<div class="o-row o-row--no-margin-bottom">
  <div class="col-xs-12 col-md-4 u-mb16@xs u-mt16@xs surgery-notify-container">
    <div *ngIf="!notified" data-cy="notify-container">
      <ng-container *ngTemplateOutlet="notifyMe"></ng-container>
    </div>

    <div *ngIf="notified" data-cy="notified-container">
      <ng-container *ngTemplateOutlet="requestedNotify"></ng-container>
    </div>
  </div>
</div>

<ng-template #notifyMe>
  <h1 class="u-mb24@xs u-font-style-h3">
    Add your email address to be notified<span class="u-sr-only"
      >contains a form with an email input
    </span>
  </h1>
  <p class="u-mb40@xs">We will only be in touch when your surgery partners with us.</p>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="c-form-part__form">
    <div
      class="c-form-part__field-wrap"
      [class.c-form-part__entry--is-invalid]="form.invalid && form.touched">
      <label for="ipt-email"> Email address: </label>
      <input
        tabindex="0"
        formControlName="email"
        name="email"
        class="c-form-part__field"
        id="ipt-email"
        required
        aria-required="true"
        type="email"
        data-cy="email-input" />
      <p
        class="c-form-part__error-message"
        id="err-msg-pattern"
        *ngIf="form.get('email').hasError('pattern')"
        role="alert"
        data-cy="email-pattern-error">
        Please use a valid email format e.g. name@domain.com
      </p>
      <p
        class="c-form-part__error-message"
        id="err-msg-required"
        *ngIf="form.get('email').hasError('required')"
        role="alert"
        data-cy="email-required-error">
        An email address is required to sign up.
      </p>
    </div>

    <button
      class="c-btn c-btn--primary"
      [disabled]="form.invalid"
      type="submit"
      data-cy="notify-me">
      Notify me
    </button>
    <button class="c-btn no-thanks-btn" (click)="goBackToHome()" data-cy="go-back-to-select">
      No thanks
    </button>
  </form>
</ng-template>

<ng-template #requestedNotify>
  <div style="text-align: center">
    <pushdr-big-green-tick></pushdr-big-green-tick>
    <h1 class="u-mb24@xs u-mt24@xs u-font-style-h3">We'll be in touch</h1>
    <p class="u-mb40@xs">
      As soon as your surgery becomes a partner you will be notified so you can get access to
      <strong>free online video consultations</strong>
    </p>
    <button class="c-btn c-btn--primary" (click)="goBackToHome()" data-cy="go-back-to-home">
      Great!
    </button>
  </div>
</ng-template>
