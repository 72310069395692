import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalService } from '@pushdr/common/overlay';
import { PDServerError } from '@pushdr/common/types';
import { HttpErrorMessageService } from '@pushdr/patientapp/common/data-access/patient-api';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { FlowStateService } from './flow-state.service';
import { Funnel, FunnelConfig, Gate, Page } from './flow-types';

@Injectable()
export class FlowService {
  private originalFunnel: Funnel = null;

  funnel$: BehaviorSubject<Funnel> = new BehaviorSubject(null);

  constructor(
    private router: Router,
    private modal: ModalService,
    private state: FlowStateService,
    private httpErrors: HttpErrorMessageService,
    private app: ApplicationRef,
    @Inject('FunnelResolver') funnelPromise: Promise<Funnel>
  ) {
    funnelPromise.then(funnel => {
      this.initialise(funnel);
      this.app.tick();
    });
  }

  initialise(funnel: Funnel) {
    this.originalFunnel = Object.assign({}, funnel);
    this.funnel = funnel;
  }

  // resets the navigations flow to original
  reset() {
    this.funnel = Object.assign({}, this.originalFunnel);
  }

  get funnel() {
    return this.funnel$.value;
  }

  set funnel(flow: Funnel) {
    this.funnel$.next(flow);
  }

  get config$() {
    const defaultConfig: FunnelConfig = {
      showSocialSignButtons: false,
    };
    return this.funnel$.pipe(map(funnel => (funnel ? funnel.config : defaultConfig)));
  }

  next<T>(queryParams = {}, callBack?: { callBack; streamToSwitch: Observable<T> }) {
    const apiCall = this.state.apiRequestForPage(this.relativePage().current);
    if (apiCall) {
      this.submit(apiCall, callBack);
      return apiCall;
    } else {
      this.goToNextPage(queryParams);
    }
  }

  back() {
    this.navTo(this.relativePage().prev.link);
  }

  lock(pageNamesToUnlock: string[]) {
    return this.setPageLockTo(pageNamesToUnlock, true);
  }

  unlock(pageNamesToUnlock: string[]) {
    return this.setPageLockTo(pageNamesToUnlock, false);
  }

  setPageLockTo(pageNamesToUnlock: string[], locked = true) {
    const changedPages: Page[] = [];
    this.allPagesWithModels.forEach(page => {
      const needsChanging = page.locked !== locked;
      const matchesPageName = pageNamesToUnlock.indexOf(page.name) > -1;
      if (matchesPageName) {
        changedPages.push(page);
        if (needsChanging && matchesPageName) {
          page.locked = locked;
        }
      }
    });
    return changedPages;
  }

  gotoPage(pageToGoto: Page | string, queryParams = {}) {
    if (typeof pageToGoto === 'string') {
      return this.allPagesWithModels.find(page => {
        if (page.link === pageToGoto || page.name === pageToGoto) {
          this.navTo(page.link, queryParams);
          return true;
        }
      });
    } else if (pageToGoto) {
      this.navTo(pageToGoto.link, queryParams);
      return true;
    }
    throw Error('Flowservice - invalid pageToGoto');
  }

  getGate(gateName: string) {
    return this.funnel.gates.find(gate => gate.name === gateName);
  }

  gotoGate(gateToGoto: Gate | string, preserveQueryParams = false) {
    if (typeof gateToGoto === 'string') {
      const found = this.funnel.gates.find(gate => {
        if (gate.name === gateToGoto && Array.isArray(gate.pages) && gate.pages.length) {
          this.navTo(gate.pages[0].link, {}, preserveQueryParams);
          return true;
        }
      });
      console.log(gateToGoto, found ? 'found' : 'not found');
    } else if (gateToGoto && Array.isArray(gateToGoto.pages) && gateToGoto.pages.length) {
      this.navTo(gateToGoto.pages[0].link, {}, preserveQueryParams);
      return true;
    } else throw Error('Flowservice - invalid gateToGoto');
  }

  get getPercentageComplete(): string {
    if (!this.funnel) return '100';
    const percentageComplete: number =
      (this.getCurrentStepNumber / (this.getNumberOfSteps - 1)) * 100;
    if (percentageComplete) {
      return percentageComplete.toFixed(2);
    }
    return '0';
  }

  get getCurrentStepNumber(): number {
    let lastNonLockedStep = -1;
    const currentPage = this.relativePage().current;
    if (!currentPage) return lastNonLockedStep;
    const pgIndex = this.allPagesWithModels.findIndex(pg => {
      const isPage = currentPage.link === pg.link;
      const hasLockedParam = Object.prototype.hasOwnProperty.call(pg, 'locked');
      if (!hasLockedParam) {
        lastNonLockedStep += 1;
      }
      if (isPage) {
        return true;
      }
    });
    console.log(pgIndex, 'last page unlocked');
    return lastNonLockedStep;
  }

  /* private helpers */

  private navTo(link, queryParams = {}, preserveQueryParams = false) {
    if (!link) {
      console.log('Flow service : link was undefined');
      return;
    }

    // parse the link for query params and extend the queryParams object
    const [route, paramsString] = link.split('?');
    (paramsString || '').split('&').forEach(keyEqVal => {
      const [key, val] = keyEqVal.split('=');
      queryParams[key] = val;
    });

    const extras: NavigationExtras = { queryParams };
    if (preserveQueryParams) {
      extras.queryParamsHandling = 'preserve';
    }
    this.router.navigate([route], extras);
  }

  private get allPagesWithModels(): Page[] {
    let pages = [];
    this.funnel.gates.forEach(gate => {
      // associate the model to the page based on name of gate it lived in
      const pagesWithModels = gate.pages.map(pg => {
        pg.model = this.state.getModelByName(gate.name);
        pg.gate = gate;
        return pg;
      });
      pages = [...pages, ...pagesWithModels];
    });
    return pages;
  }

  private getModalParamsFromError(page: Page, error: PDServerError): PDServerError {
    if (!page.submit) {
      return null;
    }
    switch (true) {
      case page.submit.registration:
        return this.httpErrors.account.createUpdateErrors(error);
      case page.submit.patient: {
        return this.httpErrors.customer.updateCustomerErrors(error);
      }
      case page.submit.verify:
        return this.httpErrors.addresses.commonErrors(error);
      default:
        return null;
    }
  }

  /**
   * Takes a charged observable ASYNC request which when subscribed handles loader and shows errors...
   * @param submitRequest api request
   */
  private submit<T, U>(
    submitRequest: Observable<T>,
    callBack?: { callBack; streamToSwitch: Observable<U> },
    queryParams?
  ) {
    this.modal.showLoader();
    submitRequest
      .pipe(
        tap(response => {
          if (callBack) {
            callBack.callBack(response);
          }
        }),
        switchMap(res =>
          iif(() => !!callBack?.streamToSwitch, callBack?.streamToSwitch || of({}), of({}))
        )
      )
      .subscribe(
        () => {
          this.modal.close();
          this.goToNextPage(queryParams);
        },
        (error: PDServerError) => {
          const params = this.getModalParamsFromError(this.relativePage().current, error);
          this.modal.error(params.message);
        }
      );
  }

  private goToNextPage(queryParams = {}) {
    const { current, next, prev } = this.relativePage();
    if (next && next.link !== current.link) {
      this.navTo(next.link, queryParams);
    } else {
      console.log('No next page or next page is same as current');
    }
  }

  private relativePage(overrideUrl?: string): { current: Page; next: Page; prev: Page } {
    let current = null;
    let next = null;
    let prev = null;
    const currentPagePath: string = overrideUrl
      ? overrideUrl
      : this.noQueryParamsRoute(this.router.url);

    this.allPagesWithModels.forEach(pg => {
      // if current and not next find next non locked page in flow
      if (current && !next && !pg.locked) {
        next = pg;
      }

      // finding current page
      if (this.noQueryParamsRoute(pg.link) === currentPagePath) {
        current = pg;
      }

      // store the prev that wasn't locked
      if (!pg.locked && !current) {
        prev = pg;
      }
    });

    return {
      current,
      next: next || current,
      prev: prev || current,
    };
  }

  private noQueryParamsRoute(route): string {
    return route.split('?')[0];
  }

  // for now we're only counting the original steps e.g. ignore the ones where locked is set
  private get getNumberOfSteps(): number {
    return this.allPagesWithModels.filter(pg => {
      return !Object.prototype.hasOwnProperty.call(pg, 'locked');
    }).length;
  }
}
