import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';

import { ZENDESK_PUBLIC_KEY } from './models';
import { ZendeskApiService } from './zendesk-api.service';

@Injectable({
  providedIn: 'root',
})
export class ZendeskService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(ZENDESK_PUBLIC_KEY) private zendeskKey: string,
    private zendeskApiService: ZendeskApiService
  ) {}

  bootstrapWidgetSnippet(): Promise<void> {
    return new Promise(resolve => {
      const key = this.zendeskKey;
      const scriptUrl = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
      const script = this.document.createElement('script');
      script.id = 'ze-snippet';
      script.src = scriptUrl;
      script.onload = () => resolve();
      script.onerror = () => resolve();

      // Bootstraps the zendesk widget
      this.document.body.appendChild(script);
    });
  }

  logIn(): Observable<boolean> {
    this.logOut(); // <--- Clears up the cache before login
    return this.zendeskApiService.getZendeskChatToken().pipe(
      tap(jwtToken => this.loginWithJwt(jwtToken)),
      mapTo(true)
    );
  }

  private loginWithJwt(jwtToken: string): void {
    zE?.('messenger', 'loginUser', (callback: (jwt: string) => void) => {
      callback(jwtToken);
    });
  }

  logOut(): void {
    zE?.('messenger', 'logoutUser');
  }

  open(): void {
    zE?.('messenger', 'open');
  }

  close(): void {
    zE?.('messenger', 'close');
  }
}
