import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NamedEncryptedUser, SocialSignInKeys } from '@pushdr/common/types';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { SignOutService, InputSanitizerService } from '@pushdr/patientapp/common/utils';
import { ModalService } from '@pushdr/common/overlay';
import { forkJoin, of, timer, Observable, Subject } from 'rxjs';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';
import { FlowStateService } from '../../services/funnel-flow/flow-state.service';
import { LoginService } from '../../services/login/login.service';
import { NhsSignInOIDCService } from '../../services/nhs-signin/nhs-sign-in.service';
import { AnalyticsService } from '@pushdr/common/data-access/analytics';

@Component({
  selector: 'pushdr-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {
  Email: string = this.state.registration.Email;
  Mobile: string = this.state.registration.Mobile;
  Password = '';
  redirectDelayTimer$: Observable<number>;
  private ngUnsubscribe$ = new Subject<void>();
  redirecting = false;

  MobileLogin = false;
  EmailLogin = false;
  BothLogin = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private api: ApiNHSPatientService,
    private modal: ModalService,
    private state: FlowStateService,
    private login: LoginService,
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
    private signoutService: SignOutService,
    private sanitizer: InputSanitizerService,
    private nhsSignInOIDCService: NhsSignInOIDCService,
    private flowStateService: FlowStateService
  ) {}

  ngOnInit() {
    const withField = this.route.snapshot.queryParams['with'];
    this.MobileLogin = this.state.registration.Mobile && withField === 'mobile';
    this.EmailLogin = !!this.state.registration.Email && withField === 'email';
    this.BothLogin = !withField || (!this.MobileLogin && !this.EmailLogin);

    // Redirect to login NHS when redirected from sign-up page
    if (this.route.snapshot.queryParams['signInWithNhs']) {
      setTimeout(() => {
        this.signInWithNHS();
        this.changeDetector.markForCheck();
      }, 0);
    }
  }

  ngOnDestroy() {
    this.redirecting = false;
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  submit() {
    this.modal.showLoader({
      bottomText: `Logging in`,
    });
    this.Mobile = this.sanitizer.username(this.Mobile);
    this.Email = this.sanitizer.username(this.Email);
    this.signIn(this.Email || this.Mobile, this.Password).subscribe((user: NamedEncryptedUser) => {
      if (user) {
        this.login.processUser(user, SocialSignInKeys.NONE, this.Password);
      }
    });
  }

  signInWithNHS() {
    this.redirecting = true;
    this.redirectDelayTimer$ = timer(3000).pipe(takeUntil(this.ngUnsubscribe$));

    this.redirectDelayTimer$.subscribe(() => {
      this.removePreselectedSurgeryCookie();
      this.signoutService.flushUser().subscribe(() => {
        this.nhsSignInOIDCService.getAuthCode();
      });
    });
  }

  private signIn(username: string, password: string) {
    this.removePreselectedSurgeryCookie();
    const flush$ = this.signoutService.flushUser();
    const login$ = this.api.authentication
      .login(username, password)
      .pipe(map(user => ({ EncryptedUser: user })));

    return forkJoin([flush$, login$]).pipe(
      map(([, loginRes]) => loginRes),
      catchError(err => {
        this.login.errorHandler(err);
        return of(null);
      }),
      tap(() => this.fireEventOnceFireBaseIsReady({ action: 'login' }))
    );
  }

  fireEventOnceFireBaseIsReady(event) {
    this.analytics.fireBaseInitialized$.pipe(take(1)).subscribe(() => {
      this.analytics.trackEvent(event);
    });
  }

  private removePreselectedSurgeryCookie() {
    this.flowStateService.clearSelectedSurgeryStore();
  }
}
