<section class="c-form-part" trackView="verify code">
  <h1 class="c-form-part__title" pdFocus="true">
    Verify your mobile
    <span class="u-sr-only"
      >, On this screen you will use a code sent to your mobile phone number to verify it is
      yours</span
    >
  </h1>
  <p
    tabindex="0"
    class="u-font-weight-normal"
    attr.aria-label="We have sent a text to mobile phonenumber ending in {{
      mobileEnding().split('').join(' ')
    }}">
    We’ve sent a text to: {{ mobileMasked }}
  </p>

  <p tabindex="0" id="verify-mobile-description">
    Please verify your mobile with the 4 digit code included in the text
  </p>

  <form novalidate #f="ngForm" (ngSubmit)="f.valid && submit(f)">
    <div
      class="c-form-part__field-wrap"
      [class.c-form-part__entry--is-invalid]="f.submitted && code.invalid">
      <label class="c-form-part__field-label" for="ipt-strVerificationCode"
        >Verification code:</label
      >
      <input
        aria-required="true"
        class="c-form-part__field"
        id="ipt-strVerificationCode"
        name="code"
        required
        ngModel
        #code="ngModel"
        type="text"
        maxlength="4"
        minlength="4"
        type="tel"
        autocomplete="off"
        aria-describedby="verify-mobile-description" />
      <p class="c-form-part__error-message" *ngIf="code?.errors?.required" role="alert">
        Please enter the 4 digit verification code in your text message.
      </p>
    </div>
    <button
      class="c-btn c-btn--primary c-form-part__btn"
      type="submit"
      trackClick="submit"
      aria-label="Submit the verification code">
      Submit
    </button>

    <button
      (click)="sendSms()"
      type="button"
      class="c-btn c-btn--secondary c-form-part__btn"
      aria-label="Send SMS code to me again"
      trackClick="resend"
      value="Choose option">
      Resend
    </button>
  </form>
</section>
