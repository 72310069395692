<div class="c-surgery-listview">
  <ul class="c-listview">
    <li
      class="c-listview__item"
      *ngFor="let surgery of surgeries"
      (click)="selectSurgery.emit(surgery)"
      (keyup.enter)="selectSurgery.emit(surgery)"
      trackClick="select"
      tabindex="0"
      role="button"
      #surgeryItem>
      <span class="mr-auto">
        <p class="u-font-weight-normal c-listview__heading">
          {{ surgery.Name }}
          {{ surgery.DistanceDescription ? '(' + surgery.DistanceDescription + ')' : '' }}
        </p>
        <p>{{ surgery.Address }}</p>
      </span>
    </li>
  </ul>
</div>
