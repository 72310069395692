<div class="max-w-sm m-auto xs:min-h-screen md:min-h-0 pb-[100px]">
  <div class="bg-[#FFEBEE] py-8 px-4">
    <h3 class="text-[#D50000]">Is it an emergency?</h3>
    <p class="mb-2">If you are experiencing any of the below, please call 999 or go to A&E.</p>
    <h4 class="text-base mb-2">Chest problems</h4>
    <p class="mb-2">Tight squeezing in the centre of your chest</p>
    <p class="mb-2">Gasping for air or choking</p>
    <h4 class="text-base mb-2">Severe injuries</h4>
    <p class="mb-2">Heavy bleeding that you can't stop</p>
    <p class="mb-2">Deep cuts or severe injuries you aren't able to dress yourself</p>
    <h4 class="text-base mb-2">Movement problems</h4>
    <p class="mb-2">Facial drooping on one side of your face</p>
    <p class="mb-2">Suddenly being unable to hold both arms up</p>
    <p class="mb-2">Suddenly having difficulty speaking</p>
    <h4 class="text-base mb-2">Loss of control</h4>
    <p class="mb-2">Uncontrollable shaking or jerking</p>
    <p class="mb-2">Unconsciousness</p>
  </div>
</div>

<div
  class="fixed bottom-0 left-0 right-0 w-full gap-4 p-2 py-6 m-auto text-center bg-white border-t bottom-fixed border-grey-300">
  <div class="flex w-full gap-4 m-auto xs:max-w-md md:max-w-sm">
    <button
      class="w-full m-0 c-btn c-btn--primary"
      [routerLink]="['/booking/preage']"
      type="button">
      I don't have an emergency
    </button>
  </div>
</div>
