import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentProxyService } from '@pushdr/environment';
import { Observable } from 'rxjs';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { ClinicianType } from '@pushdr/common/types';

export interface QuestionnaireCta {
  title: string;
  path: string;
  type: number;
  order: number;
  requiresValidation: boolean;
  nextPageId: string;
  clinicianType: ClinicianType;
}

export interface QuestionnaireValidations {
  mandatory: boolean;
  minimum?: any;
  maximum?: any;
  invalidCharacters?: any;
}

export interface QuestionnaireInput {
  id: string;
  type: number;
  label: string;
  validations: QuestionnaireValidations;
  placeholder?: any;
  options?: QuestionnaireInputOptions[];
  value?: any;
}

export interface QuestionnaireInputOptions {
  id: string;
  pageId: string;
  path: string;
  title: string;
  value?: boolean;
}
export interface QuestionnairePage {
  id: string;
  title: string;
  header: string;
  content: string;
  cta: QuestionnaireCta[];
  inputs: QuestionnaireInput[];
  ctaProperties?: CtaProperties;
}

export interface CtaProperties {
  alignment: CtaPropertiesAlignmentEnum;
}

export enum CtaPropertiesAlignmentEnum {
  VERTICAL,
  HORIZONTAL,
}

export interface SignpostingQuestionnaire {
  id: string;
  firstPageId: string;
  pages: QuestionnairePage[];
}

export interface QuestionnaireResponse {
  inputId: string;
  value: string;
}

export interface SubmitQuestionnaireResponse {
  path: string;
}

@Injectable({
  providedIn: 'root',
})
export class SignpostingQuestionnaireAPIService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.questionnaireAPI;
  }

  getQuestionnaire(questionnaireId: string): Observable<SignpostingQuestionnaire> {
    return this.get(`/Questionnaire/${questionnaireId}`, {}, this.headerService.headers(), 1);
  }

  submitQuestionnaireResponse(
    questionnaireId: string,
    userInputs: QuestionnaireResponse[]
  ): Observable<SubmitQuestionnaireResponse> {
    return this.post(
      `/Questionnaire`,
      { questionnaireId, userInputs },
      this.headerService.headers(),
      1
    );
  }
}
