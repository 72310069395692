import { Injectable } from '@angular/core';
import { ApiAccountPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { Observable, of } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { TokenService } from '@pushdr/common/utils';
import { userHasValidSurgery } from '../user-has-valid-surgery/user-has-valid-surgery.util';
import { AccountProfileService } from '../account-profile/account-profile.service';
import { private_registration } from '@pushdr/common/utils';

/**
 * Auth in pushdr is a little clunky and can be some what confusing
 * we have two states of auth and too many resume points in the funnel
 * this authenticated service is simply a way to say if a user
 * is fully authenticated e.g. has a token, which lets them get
 * their GPDetails.
 */
@Injectable({ providedIn: 'root' })
export class IsAuthenticatedService {
  constructor(
    private account: ApiAccountPatientService,
    private tokenService: TokenService,
    private profile: AccountProfileService
  ) {}

  isAuthenticated$(): Observable<boolean> {
    if (!this.tokenService.get()) {
      return of(false);
    }
    return this.account.getGP().pipe(
      map(gp => {
        // Replaces optimizely 'private_registration' flag
        // TODO: Add proper config for 'private_registration' flag
        const privateRegistrationConfig = private_registration;
        if (privateRegistrationConfig.enabled) {
          return !!gp && gp.Id > 0;
        }
        return (
          !!gp && userHasValidSurgery(gp.Id, gp.ShareStatus, gp.PartnershipType, this.profile.isNHS)
        );
      }),
      catchError(() => of(false)),
      share()
    );
  }
}
