import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SpinnerLoaderModule } from '@pushdr/common/components';
import {
  SharedEditFormModule,
  PatientappCommonComponentsModule,
} from '@pushdr/patientapp/common/components';
import { MobileNumInputTrimModule } from '../common/mobilefix/mobilenumfix.module';
import { SurgerySelectorComponent } from './surgery-selector/surgery-selector.component';
import { VerificationRoutingModule } from './verification-routing.module';
import { VerificationSuccessComponent } from './verificationsuccess/verificationsuccess.component';
import { VerifyMobileComponent } from './verifymobile/verifymobile.component';
import { SurgerySelectedComponent } from '../common/surgery/surgery-selected/surgery-selected.component';
import { SurgeryNotifyComponent } from '../common/surgery/surgery-notify/surgery-notify.component';
import { HasCompletedProfileGateGuard } from './has-completed-profile.guard';
import { AccountProfileModule } from '@pushdr/patientapp/common/utils';
import { LottieModule } from 'ngx-lottie';

@NgModule({
  imports: [
    CommonModule,
    AccountProfileModule,
    VerificationRoutingModule,
    LottieModule,
    FormsModule,
    RouterModule,
    MobileNumInputTrimModule,
    SpinnerLoaderModule,
    SharedEditFormModule,
    ReactiveFormsModule,
    PatientappCommonComponentsModule,
  ],
  declarations: [
    VerifyMobileComponent,
    SurgerySelectorComponent,
    VerificationSuccessComponent,
    SurgerySelectedComponent,
    SurgeryNotifyComponent,
  ],
  providers: [HasCompletedProfileGateGuard],
})
export class VerificationModule {}
