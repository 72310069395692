import { Component } from '@angular/core';
import { EnvironmentProxyService } from '@pushdr/environment';
import { PatientPricingService } from '@pushdr/patientapp/common/services';

@Component({
  selector: 'pushdr-home-private',
  templateUrl: './home-private.component.html',
})
export class HomePrivateComponent {
  appointmentPrice$ = this.price.appointmentPrice$;

  get pdPrivateWebsiteDownloadPage() {
    return this.envProxy.environment.patient.pdPrivateWebsiteDownloadPage;
  }

  constructor(private price: PatientPricingService, private envProxy: EnvironmentProxyService) {}
}
