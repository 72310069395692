import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsentInfoComponent } from './consent-info/consent-info.component';
import { RecordAccessModalComponent } from './record-access-modal/record-access-modal.component';
import { RecordingAcceptationModalComponent } from './recording-acceptation-modal/recording-acceptation-modal.component';
import { SpinnerLoaderModule } from '@pushdr/common/components';

@NgModule({
  imports: [CommonModule, SpinnerLoaderModule],
  declarations: [
    ConsentInfoComponent,
    RecordAccessModalComponent,
    RecordingAcceptationModalComponent,
  ],
  exports: [ConsentInfoComponent, RecordAccessModalComponent, RecordingAcceptationModalComponent],
})
export class ConsentComponentsModule {}
