import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputCheckboxComponent, SpinnerLoaderModule } from '@pushdr/common/components';
import { TermsComponent } from './terms.component';
import { AnalyticsDirectivesModule } from '@pushdr/common/data-access/analytics';
import { FocusOnElementModule } from '@pushdr/common/utils';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AnalyticsDirectivesModule,
    SpinnerLoaderModule,
    FocusOnElementModule,
    InputCheckboxComponent,
  ],
  exports: [TermsComponent],
  declarations: [TermsComponent],
})
export class TermsModule {}
