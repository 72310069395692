<ng-container *ngIf="surgeriesObservable$ | async as surgeries; else loading">
  <ng-container trackView="surgery select">
    <h3 class="u-mb24@xs" pdFocus="true" *ngIf="title">My GP surgery</h3>
    <div>
      <p class="u-mb0@xs" tabindex="0">Enter your surgery name or postcode</p>
    </div>
    <section class="o-row o-row--no-margin-bottom">
      <div class="col-xs-12">
        <div class="c-postcode-checker js-postcode-checker">
          <div class="c-postcode-checker__inputs">
            <form
              novalidate
              class="c-postcode-checker__input-item c-postcode-checker__input-item--is-visible js-postcode-form"
              #f="ngForm"
              (ngSubmit)="f.valid && submitPostcode(f.value.postcodeOrSurgeryName)">
              <div class="c-search-input u-font-style-p">
                <span class="c-search-input__input_wrap">
                  <input
                    aria-label="Enter your home post code and search then pick a surgery from the list. The current inputted postcode is"
                    type="search"
                    placeholder="Enter surgery or postcode"
                    class="c-search-input__field u-mb16@xs"
                    name="postcodeOrSurgeryName"
                    data-postcode-type="home"
                    [(ngModel)]="postcodeOrSurgeryName"
                    autocomplete="postal-code"
                    #searchpostcode="ngModel"
                    trimOnBlur />
                  <button
                    aria-label="Search for surgeries near your home"
                    type="submit"
                    [trackClick]="'search' + counter"
                    class="c-search-input__btn-search js-postcode-home-check-submit"
                    (click)="setCounter()">
                    Search
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
        <ng-container *ngIf="surgeries.length || !fetched; else noResult">
          <button
            class="mb-4 text-primary"
            type="button"
            (click)="help()"
            trackClick="I can't find my GP surgery">
            I can't find my GP surgery
          </button>
          <pushdr-surgery-listview [surgeries]="surgeries" (selectSurgery)="selectSurgery($event)">
          </pushdr-surgery-listview>
          <button
            *ngIf="enableSelectNone"
            type="button"
            class="text-primary"
            (click)="selectNoSurgery()"
            trackClick="noGP">
            I don’t have details for my GP surgery
          </button>
        </ng-container>
        <ng-template #noResult>
          <button
            class="mb-4 text-primary"
            type="button"
            (click)="help()"
            trackClick="I can't find my GP surgery">
            I can't find my GP surgery
          </button>
          <p>
            Sorry, we can’t find any surgeries from those details. Please try again or click
            <b>‘I can’t find my GP surgery’</b> for more help.
          </p>
        </ng-template>
      </div>
    </section>
  </ng-container>
</ng-container>

<!-- Loader spinner for the page -->
<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>
