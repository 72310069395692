import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionRequest } from '@pushdr/common/types';
import { StorageService } from '@pushdr/common/utils';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, take, tap } from 'rxjs/operators';
import { PatientMarketplaceService } from '../patient-marketplace/patient-marketplace.service';

const INVITE_COOKIE_NAME = '_ar_id_';

@Injectable({
  providedIn: 'root',
})
export class ActionRequestService {
  private _actionRequestDetails: ActionRequest;

  constructor(
    private route: ActivatedRoute,
    private api: ApiNHSPatientService,
    private store: StorageService,
    private marketplace: PatientMarketplaceService
  ) {}

  // get invite start with url, fallback to cookie, fallback to the API
  getActionRequest$(): Observable<ActionRequest> {
    return this.getActionRequestIdFromLocalState$().pipe(
      switchMap(arId => {
        return arId ? this.getActionRequestDetails$(arId) : of(null);
      }),
      catchError(err => {
        this.clearState();
        throw err;
      }),
      take(1)
    );
  }

  getActionRequestDetails$(actionRequestId: string) {
    if (!this._actionRequestDetails || this._actionRequestDetails.id !== actionRequestId) {
      this._actionRequestDetails = null;
      return this.api.actionRequest.getActionRequest(actionRequestId).pipe(
        tap(ar => {
          this._actionRequestDetails = ar;
          this.storeId(ar.id);
          this.marketplace.storePartnerId(ar.partnerSetupActionRequest.partnerId);
        })
      );
    }
    return of(this._actionRequestDetails);
  }

  getActionRequestIdFromLocalState$() {
    return this.getIdFromUrl().pipe(map(res => res || this.getIdFromCookie() || null));
  }

  claim$() {
    return this.getActionRequest$().pipe(
      switchMap(aReq => (aReq ? this.api.actionRequest.claimActionRequest(aReq.id) : of(null))),
      catchError(err => {
        this.clearState();
        throw err;
      }),
      tap(() => this.clearState())
    );
  }

  clearState() {
    this._actionRequestDetails = null;
    this.store.delete(INVITE_COOKIE_NAME);
  }

  private getIdFromUrl(): Observable<string> {
    return this.route.queryParams.pipe(
      debounceTime(1000),
      take(1),
      map(params => params.ar_id)
    );
  }

  private getIdFromCookie(): string {
    return this.store.get(INVITE_COOKIE_NAME);
  }

  private storeId(actionRequestId) {
    this.store.set(INVITE_COOKIE_NAME, actionRequestId);
  }
}
