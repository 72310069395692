<div class="c-form-part" trackView="what is nhs login">
  <section class="u-mb24@xs">
    <h2 pdFocus="true">What is NHS login?</h2>
    <p>
      NHS login allows you to securely log in to digital health care services using a single login.
    </p>
  </section>

  <section class="u-mb24@xs">
    <h4>Already got an NHS login</h4>
    <p>You will just need the email address and password you used to set it up.</p>
  </section>

  <section class="u-mb24@xs">
    <h4>Setting up an NHS login</h4>
    <p>
      You will be asked some personal details and to verify who you are, so they can connect your
      records. Here are the forms of ID you can use:
    </p>
    <ul class="u-mt8@xs verification-list">
      <li>Passport</li>
      <li>UK driving license</li>
      <li>European driving license</li>
      <li>European national identity card</li>
    </ul>
  </section>

  <section class="u-mb24@xs">
    <div class="c-notification c-notification--warning">
      <span>
        Please note it may take up to <strong>2 hours</strong> to verify a new NHS login.
      </span>
    </div>
  </section>

  <button class="c-btn c-btn--primary" (click)="goBackToSignUp()">Go back</button>
</div>
