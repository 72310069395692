import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Invite, InviteStatus, InviteType, InviteTypeEnumAsString } from '@pushdr/common/types';
import { ExtendedWindow, StorageService, WINDOW } from '@pushdr/common/utils';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { Observable, of } from 'rxjs';
import { debounceTime, map, mergeMap, take, takeWhile, tap } from 'rxjs/operators';
import { PatientMarketplaceService } from '../patient-marketplace/patient-marketplace.service';

const INVITE_COOKIE_NAME = '_invite_';

@Injectable({
  providedIn: 'root',
})
export class InviteService {
  constructor(
    private route: ActivatedRoute,
    private api: ApiNHSPatientService,
    private store: StorageService,
    private marketplace: PatientMarketplaceService,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {}

  // get invite start with url, fallback to cookie, fallback to the API
  getInvite(type: InviteType = InviteType.TBD): Observable<Invite> {
    return this.getInviteFromLocalState().pipe(
      mergeMap(res => {
        if (!res && type) {
          return this.getInviteFromAPI(type); // we're after a specific type we might be able to get one from get invites
        }
        return of(res);
      }),
      mergeMap((inv: Invite) => {
        //try to extend the invite
        if (this.isCompleteInvite(inv)) {
          return this.api.invitation.getExtendedInvite(inv).pipe(
            map(extInvite => this.mapBasedOnInviteType(extInvite, type)),
            tap(extInvite => this.storeCookieBasedOnInviteStatus(extInvite))
          );
        } else {
          return of(inv); // partial invite - poop it out... not our problem
        }
      }),
      take(1)
    );
  }

  /**
   * Try to claim an invite - gets an invite from your partners pool or your claimed ones
   * if you already have one, you get it back, other wise gets a new one
   * if he one you're claiming is complete but unclaimed, claim it PUT
   * if the one you're claiming is partial - then create it  and claim it in one go POST
   * if claimed - clear it from the cache if its there
   * if the one you're claiming is already claimed, silently continue as its claimed... we're done here
   * if want to switch to NHS account, show a message in popup
   * @param type
   */
  claimInvite(type: InviteType = InviteType.TBD): Observable<Invite> {
    return this.getInvite(type).pipe(
      mergeMap((invite: Invite) => {
        if (invite?.inviteStatus === InviteStatus.UNCLAIMED) {
          const claimer$ = this.isCompleteInvite(invite)
            ? this.api.invitation.claimTheInvite(invite)
            : this.api.invitation.createAndClaimTheInvite(invite); // create then claim a partial one
          return claimer$.pipe(tap(inv => this.flushCookieBasedOnInviteStatus(inv)));
        } else {
          this.flushCookieBasedOnInviteStatus(invite);
          return of(invite);
        }
      })
    );
  }

  spendInvite(type: InviteType) {
    const getInvite$ = this.getInvite(type).pipe(
      takeWhile(invite => {
        return (
          !!invite &&
          invite.inviteStatus === InviteStatus.CLAIMED &&
          invite.type === type &&
          !!invite.code
        );
      })
    );

    return getInvite$.pipe(mergeMap(invite => this.api.invitation.spendTheInvite(invite)));
  }

  getInviteFromLocalState() {
    return this.getInviteFromUrl().pipe(map(res => res || this.getInviteFromCookie() || null));
  }

  flushInviteCookie() {
    this.store.delete(INVITE_COOKIE_NAME);
  }

  private mapBasedOnInviteType(invite: Invite, typeWanted: InviteType) {
    return invite && (invite.type === typeWanted || typeWanted === InviteType.TBD) ? invite : null;
  }

  private storeCookieBasedOnInviteStatus(inv: Invite) {
    if (this.isCompleteInvite(inv) && !inv.inviteStatus) {
      this.store.set(INVITE_COOKIE_NAME, inv);
    } else if (this.isCompleteInvite(inv) && inv.inviteStatus > InviteStatus.UNCLAIMED) {
      this.flushCookieBasedOnInviteStatus(inv);
    }
  }

  private flushCookieBasedOnInviteStatus(inv: Invite) {
    if (inv && inv.inviteStatus > InviteStatus.UNCLAIMED) {
      this.flushInviteCookie();
    }
  }

  private getInviteFromUrl(): Observable<Invite> {
    return this.route.queryParams.pipe(
      debounceTime(1000),
      take(1),
      map(params => {
        const invite: Invite = {
          partnerId: params.partnerId,
          promotionId: params.promotionId,
          code: params.invitationId || params.code,
          type: InviteType.TBD,
        };
        if (this.isCompleteInvite(invite)) {
          this.flushSurgeryCookie();
          return invite;
        } else return null;
      })
    );
  }

  private flushSurgeryCookie() {
    this.store.delete('SURGERY_TYPE');
    this.store.delete('ODS_CODE');
  }

  private getInviteFromCookie(): Invite {
    const invite: Invite = this.store.get(INVITE_COOKIE_NAME, true);
    return this.isCompleteInvite(invite) ? invite : null;
  }

  private getInviteFromAPI(type: InviteType): Observable<Invite> {
    if (!type) {
      return of(null);
    }

    // until we have a client with multiple invite options, we just take the first invite as default
    return this.api.invitation
      .getInvites(InviteTypeEnumAsString(type), this.marketplace.getPartnerId())
      .pipe(map(results => (Array.isArray(results) && results.length ? results.shift() : null)));
  }

  private isCompleteInvite(invite: Invite) {
    return invite && invite.code && invite.partnerId && invite.promotionId;
  }
}
