import { Injectable } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
} from '@angular/router';
import { ModalService } from '@pushdr/common/overlay';

@Injectable({
  providedIn: 'root',
})
export class RoutingLoadingIndicator {
  constructor(private modal: ModalService) {}

  interceptRouterEvent(event: Event): void | never {
    if (hasStarted(event)) {
      this.modal.showLoader(void 0, 'navLoader');
    } else if (hasEnded(event)) {
      this.modal.close('navLoader');
    }
  }
}

function hasStarted(event: Event): boolean {
  return event instanceof NavigationStart;
}

function hasEnded(event: Event): boolean {
  return (
    event instanceof NavigationEnd ||
    event instanceof NavigationError ||
    event instanceof NavigationCancel
  );
}
