import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnvironmentProxyService } from '@pushdr/environment';

import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';

import {
  Acceptance,
  AcceptanceGroup,
  AcceptanceType,
  mapAcceptanceGroupToName,
  mapAcceptanceTypeToName,
} from './models';

const allGroups = [AcceptanceGroup.MARKETING, AcceptanceGroup.NHS];

@Injectable({
  providedIn: 'root',
})
export class AcceptanceApiService extends RestClient {
  constructor(
    protected http: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParser: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(http, headerService, errorParser, proxy);
  }

  endpoint = () => this.proxy.environment.patient.nhsAPI + '/acceptance';

  getAcceptances(groups = allGroups, type?: AcceptanceType): Observable<Acceptance[]> {
    const queryParams = buildQueryParams({ groups, type });
    return this.get<Acceptance[]>('', queryParams).pipe(
      // Mitigates API issue with picking up acceptance with highest version
      map(acceptances => acceptances.sort((a, b) => b.Version - a.Version))
    );
  }
}

type QueryOptions = { groups: AcceptanceGroup[]; type?: AcceptanceType };
function buildQueryParams({ groups, type }: QueryOptions): HttpParams {
  let queryParams = new HttpParams();

  if (Array.isArray(groups) && groups.length) {
    const groupNames = groups.map(mapAcceptanceGroupToName).join(',');
    queryParams = queryParams.set('groups', groupNames);
  }

  if (typeof type !== 'undefined') {
    const typeName = mapAcceptanceTypeToName(type);
    queryParams = queryParams.set('type', typeName);
  }

  return queryParams;
}
