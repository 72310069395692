const safedomains =
  /^(https:\/\/|http:\/\/).*((.pushdoctor.co.uk\/|.localpushdr.com\/|localhost:|.pushconsult.co.uk\/|.startfeelingbetter.com\/)|(.pushdoctor.co.uk$|.localpushdr.com$|.pushdr.com$|localhost:|.pushconsult.co.uk$|.startfeelingbetter.com$))/g;

export function isExternalRedirect(url: string) {
  const safeUrl = url || '';
  return safeUrl.indexOf('http') === 0;
}

export function safeExternalRedirect(url: string) {
  const safeUrl = url || '';
  const safeDomains = safedomains;
  const matched = safeUrl.match(safeDomains);
  if (isExternalRedirect(safeUrl) && matched && matched.length) {
    return safeUrl;
  } else {
    return '';
  }
}
