export enum EncryptedUserStatus {
  NO_MOBILE = 0,
  MOBILE_NOT_VERIFIED = 1,
  TOKEN_REQUIRED = 2,
  BLOCKED = 3,
}

export interface EncryptedUser {
  Email: string;
  Mobile: string;
  Status: EncryptedUserStatus;
  CustomerKey: string;
  CustomerId: number;
  ExternalId: string;
}

export const EncryptedUserFactory = (
  Status: EncryptedUserStatus,
  Email: string,
  Mobile: string
) => {
  const user: EncryptedUser = {
    Email,
    Mobile,
    Status,
    CustomerKey: 'abc123',
    CustomerId: 1,
    ExternalId: '',
  };
  return user;
};

export const SocialSignInKeys = {
  NHS: 'nhs',
  NONE: '',
};

export interface NamedEncryptedUser {
  SuggestedFirstName?: string;
  SuggestedLastName?: string;
  SuggestedUserDetails?: SuggestedUserDetails;
  EncryptedUser: EncryptedUser;
}

export interface SuggestedUserDetails {
  DateOfBirth: string; //YYYY-MM-DD
  Email: string;
  FirstName: string;
  LastName: string;
  GpOdsCose: string;
  Mobile: string;
}

export const NamedEncryptedUserFactory = (
  Status: EncryptedUserStatus,
  Email: string,
  Mobile: string,
  SuggestedFirstName = '',
  SuggestedLastName = ''
) => {
  const user: NamedEncryptedUser = {
    SuggestedFirstName,
    SuggestedLastName,
    EncryptedUser: EncryptedUserFactory(Status, Email, Mobile),
  };
  return user;
};

/** @deprecated No longer used, kept only for API capability. */
export interface GoogleAuthenticationResult {
  GoogleAuthenticationResult: NamedEncryptedUser;
}

/** @deprecated No longer used, kept only for API capability. */
export interface FacebookAuthenticationResult {
  FacebookAuthenticationResult: NamedEncryptedUser;
}

export interface NhsAuthenticationResult {
  NhsAuthenticationResult: NamedEncryptedUser;
}

export interface ResetPasswordRequest {
  Username: string;
  Mobile: string;
  VerificationCode: string;
  Password: string;
}

export interface ResetResult {
  Success: boolean;
  Message: string;
  CustomerId: number;
}
