import { Component, OnInit, Inject } from '@angular/core';
import { ExtendedWindow, WINDOW } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { Observable, forkJoin } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { ApiPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { map, switchMap, tap } from 'rxjs/operators';
import { ModalService } from '@pushdr/common/overlay';
import { nhs_registration } from '@pushdr/common/utils';
import {
  FlowStateService,
  SelectedSurgeryType,
} from '../../../services/funnel-flow/flow-state.service';
import { ActionRequestService } from '@pushdr/patientapp/common/services';
import { PartnerType } from '@pushdr/common/types';

export enum SurgeryStatus {
  NHS = 'partnered',
  PRIVATE = 'not-partnered',
}

@Component({
  selector: 'pushdr-surgery-selected',
  styleUrls: ['./surgery-selected.component.scss'],
  templateUrl: './surgery-selected.component.html',
})
export class SurgerySelectedComponent implements OnInit {
  surgeryStatus: SurgeryStatus;
  surgeryName = '';
  proceedToAccount = false;
  actionRequest$: Observable<boolean>;
  nhsConfig: any;

  constructor(
    private router: Router,
    private envProxy: EnvironmentProxyService,
    private apiNhs: ApiNHSPatientService,
    private api: ApiPatientService,
    private modal: ModalService,
    @Inject(WINDOW) private window: ExtendedWindow,
    private route: ActivatedRoute,
    private flowStateService: FlowStateService,
    private actionRequest: ActionRequestService
  ) {}

  ngOnInit() {
    this.surgeryName = this.flowStateService.getSelectedSurgeryStore().surgeryName;
    this.surgeryStatus = this.getSurgeryPartnerStatus();
    if (this.surgeryStatus === SurgeryStatus.NHS) {
      this.nhsConfig = nhs_registration;
    }
    this.proceedToAccount = this.window.location.href.indexOf('verification') > -1;

    this.actionRequest$ = this.actionRequest.getActionRequest$().pipe(
      tap(() => {
        if (this.surgeryStatus === SurgeryStatus.PRIVATE) {
          return this.router.navigate(['../private'], { relativeTo: this.route });
        }
      }),
      map(() => true)
    );
  }

  private getSurgeryPartnerStatus(): SurgeryStatus {
    return this.flowStateService.getSelectedSurgeryStore().surgeryType ===
      SelectedSurgeryType.PARTNERED
      ? SurgeryStatus.NHS
      : SurgeryStatus.PRIVATE;
  }

  createAccount() {
    this.router.navigate(['register', 'signup']);
  }

  goBack() {
    this.window.location.href = 'https://www.pushdoctor.co.uk/nhs';
  }

  goToAccount() {
    this.window.location.href = this.envProxy.environment.patient.account.url;
  }

  notifyMe() {
    if (this.proceedToAccount) {
      this.showLoader();

      const $getGpId = this.api.account.getGP().pipe(map(gp => gp.Id));
      const $getEmail = this.api.account.getDetails().pipe(map(details => details.EmailAddress));

      forkJoin([$getGpId, $getEmail])
        .pipe(
          switchMap(([gpId, email]) =>
            this.apiNhs.account.requestNotificationForGPPartner(gpId, email)
          )
        )
        .subscribe(
          res => {
            this.modal.close();
            this.router.navigate(['verification', 'surgery', 'notify']);
          },
          error => {
            this.modal.error(error);
          }
        );
    } else {
      this.router.navigate(['surgery-preselect', 'notify']);
    }
  }

  covidSymptoms() {
    this.window.location.href = 'https://www.pushdoctor.co.uk/covid-intro';
  }

  private showLoader() {
    this.modal.showLoader({
      header: 'Adding your email to be notified',
      bottomText: 'Please be patient while we add your email to the list to be notified',
    });
  }
}
