<ng-container *ngIf="appointmentPrice$ | async as appointmentPrice; else loading">
  <section class="max-w-sm m-auto text-bluegrey-800">
    <div class="pb-6 mb-6 border-b border-grey-200">
      <h3 class="mb-4 font-normal">
        <span>Improved Private GP</span>
      </h3>
      <div class="flex justify-between">
        <div>
          <h5 class="mt-0 mb-1">Private account</h5>
        </div>
        <div>
          <span class="px-4 py-2 text-sm text-indigo-700 bg-teal-500 rounded-full">Default</span>
        </div>
      </div>
    </div>
    <div class="mb-10">
      <h5 class="mt-0 mb-6">Account abilities</h5>
      <ul class="pl-4 green-checked-list">
        <li class="mb-2">Video appointments for {{ appointmentPrice / 100 | currency: 'GBP' }}</li>
        <li class="mb-2">NHS-trained and registered doctors</li>
        <li class="mb-2">Same day prescriptions</li>
        <li class="mb-2">Instant fit (sick) notes</li>
        <li class="mb-2">Family accounts available</li>
        <li class="mb-2">Appointments available 8am-8pm</li>
        <li class="mb-2">Cancel free of charge up to 2 hours before your appointment</li>
      </ul>
    </div>

    <a
      class="c-btn c-btn--primary c-form-part__btn"
      trackClick="next"
      [href]="pdPrivateWebsiteDownloadPage">
      Download New App
    </a>
  </section>
</ng-container>

<!-- Loader spinner for the page -->
<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>
