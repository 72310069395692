import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@pushdr/common/overlay';
import { InputSanitizerService } from '@pushdr/patientapp/common/utils';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { FlowStateService } from '../../../services/funnel-flow/flow-state.service';

@Component({
  selector: 'pushdr-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent {
  constructor(
    private api: ApiNHSPatientService,
    private modal: ModalService,
    private router: Router,
    private state: FlowStateService,
    private sanitizer: InputSanitizerService
  ) {}

  strMobile = '';
  strEmail = '';

  submit() {
    this.modal.showLoader();
    this.strEmail = this.sanitizer.username(this.strEmail);
    this.strMobile = this.sanitizer.username(this.strMobile);
    this.api.authentication.forgotPassword(this.strEmail, this.strMobile).subscribe(
      response => {
        this.state.registration.Email = this.strEmail;
        this.state.registration.Mobile = this.strMobile;
        this.state.updateRegistrationModel(this.state.registration);
        this.router.navigate(['/register/resetpassword']);
        this.modal.close();
      },
      error => {
        const { message } = this.api.errorMessages.authentication.forgotPassword(error);
        this.modal.error(message);
      }
    );
  }
}
