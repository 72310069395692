<main
  trackView="pre-surgery select"
  class="o-row o-row--margin-bottom-small u-mt24@xs"
  *ngIf="!preSelected">
  <section class="col-xs-12 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">
    <div *ngIf="!searched">
      <h1 class="u-mb24@xs u-font-style-h3">
        Find your GP Surgery
        <span class="u-sr-only"> contains an input to search your postcode for your GP </span>
      </h1>
      <p>Your GP surgery will tell us if you are eligible for free NHS digital appointments.</p>
    </div>
    <pushdr-edit-surgery
      [title]="false"
      [preload]="false"
      (searched)="searchedToggle()"
      (selected)="selectSurgery($event)">
    </pushdr-edit-surgery>
  </section>
</main>
