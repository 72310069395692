import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';

@Injectable()
export class PdValidators {
  static minDate(minDate: Moment | string, validateTime = false): ValidatorFn {
    if (typeof minDate === 'string') minDate = moment(minDate);
    return (control: AbstractControl): { [key: string]: any } | null => {
      let controlDate = control.value;
      if (typeof controlDate === 'string') controlDate = moment(controlDate);
      return controlDate && controlDate < minDate
        ? { [validateTime ? 'minDateTime' : 'minDate']: { value: control.value } }
        : null;
    };
  }

  static maxDate(maxDate: Moment | string, validateTime = false): ValidatorFn {
    if (typeof maxDate === 'string') maxDate = moment(maxDate);
    return (control: AbstractControl): { [key: string]: any } | null => {
      let controlDate = control.value;
      if (typeof controlDate === 'string') controlDate = moment(controlDate);
      return controlDate && controlDate > maxDate
        ? { [validateTime ? 'maxDateTime' : 'maxDate']: { value: control.value } }
        : null;
    };
  }

  static minTime(minTime: string | Moment): ValidatorFn {
    if (typeof minTime === 'string') minTime = moment(`0001-01-01T${minTime}`);
    return (control: AbstractControl): { [key: string]: any } | null => {
      let controlDate = control.value;
      if (typeof controlDate === 'string') controlDate = moment(`0001-01-01T${controlDate}:00`);
      return controlDate && controlDate < minTime ? { minTime: { value: control.value } } : null;
    };
  }

  static maxTime(maxTime: string | Moment): ValidatorFn {
    if (typeof maxTime === 'string') maxTime = moment(`0001-01-01T${maxTime}`);
    return (control: AbstractControl): { [key: string]: any } | null => {
      let controlDate = control.value;
      if (typeof controlDate === 'string') controlDate = moment(`0001-01-01T${controlDate}:00`);
      return controlDate && controlDate > maxTime ? { maxTime: { value: control.value } } : null;
    };
  }

  static noWhiteSpace(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid || !control?.value ? null : { whitespace: true };
    };
  }
}
