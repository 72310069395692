import { Directive, HostListener } from '@angular/core';
import { ZendeskService } from './zendesk.service';

@Directive({
  selector: '[pushdrZendesk]',
})
export class ZendeskDirective {
  constructor(private zendeskService: ZendeskService) {}

  @HostListener('click')
  onClick() {
    this.zendeskService.open();
  }
}
