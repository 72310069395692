<div class="flex items-start gap-4">
  <div class="w-1/2 justify-center hidden md:flex">
    <img
      class="max-w-full w-1/2 mx-10 my-4"
      src="assets/images/mobile-doctor.png"
      alt="Mobile Doctor" />
  </div>
  <div class="w-full md:w-1/3">
    <h1 class="text-indigo-500 text-[1.625rem] leading-[35px] mb-6">
      You are eligible for free online NHS services in partnership with your GP surgery
    </h1>

    <div class="mb-8">
      <ng-container *ngIf="surgeryName$ | async as surgeryName; else loading">
        <h2 class="text-base font-normal leading-none mb-3">{{ surgeryName }}</h2>
        <ul class="mb-0 green-checked-list">
          <li class="mb-[3px]">Free online GP appointments</li>
          <li class="mb-[3px]">NHS prescriptions</li>
          <li class="mb-[3px]">Fit (Sick) notes and NHS referrals</li>
        </ul>
      </ng-container>
    </div>

    <button class="c-btn c-btn--primary" [routerLink]="['/booking', 'emergency']">
      Book an appointment
    </button>
  </div>
</div>

<ng-template #loading>
  <pushdr-spinner-loader [minheight]="0"></pushdr-spinner-loader>
</ng-template>
