import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';

import { EnvironmentProxyService } from '@pushdr/environment';
import { CustomerAcceptance } from './models';

@Injectable({
  providedIn: 'root',
})
export class ConsentApiService extends RestClient {
  constructor(
    protected http: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParser: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(http, headerService, errorParser, proxy);
  }

  endpoint = () => this.proxy.environment.patient.nhsAPI + '/Customer/acceptances';

  getConsents(): Observable<CustomerAcceptance[]> {
    const headers = this.headerService.headers() ?? new HttpHeaders();
    return this.get<CustomerAcceptance[]>('', {}, headers).pipe(
      // Mitigates API issue with picking up acceptance with highest version
      map(consents => consents.sort((a, b) => b.Version - a.Version))
    );
  }

  updateConsents(acceptances: CustomerAcceptance[]): Observable<void> {
    const headers = this.headerService.headers() ?? new HttpHeaders();
    return this.post('', acceptances, headers);
  }
}
