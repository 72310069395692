<ng-container *ngIf="actionRequest$ | async as actionRequest; else loading">
  <div *ngIf="surgeryStatus; else loading" class="o-row o-row--no-margin-bottom">
    <div class="col-xs-12 u-mb16@xs u-mt16@xs">
      <div data-cy="partnered-container">
        <main class="o-row o-row--no-margin-bottom">
          <div class="col-xs-12 col-md-6">
            <figure>
              <img
                alt="mobile doctor"
                class="mobile-doctor"
                aria-hidden="true"
                src="./assets/images/mobile-doctor.png" />
            </figure>
          </div>
          <div class="text-left col-xs-12 col-md-4">
            <h1 class="u-mb24@xs u-font-style-h3 text-indigo-500">
              You are eligible for free online NHS services in partnership with your GP surgery
            </h1>
            <span class="font-normal">{{ surgeryName }}</span>
            <ul class="mb-0 green-checked-list">
              <li
                *ngFor="let upsell of nhsConfig.nhsUpsellArr | slice: 0:3; let isLast = last"
                [class.mb-0]="isLast"
                [innerHTML]="upsell"></li>
            </ul>
            <button
              class="mt-12 c-btn c-btn--primary"
              type="submit"
              (click)="createAccount()"
              data-cy="create-account">
              Create your account
            </button>
          </div>
        </main>
      </div>
    </div>
  </div>
</ng-container>

<!-- Loader spinner for the page -->
<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>
