import { MobileNumInputTrimDirective } from './mobilenumfix.directive';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [],
  exports: [MobileNumInputTrimDirective],
  declarations: [MobileNumInputTrimDirective],
  providers: [],
})
export class MobileNumInputTrimModule {}
