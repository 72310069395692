import { Component, OnInit, Inject } from '@angular/core';
import { ExtendedWindow, WINDOW } from '@pushdr/common/utils';
import { Router } from '@angular/router';

@Component({
  selector: 'pushdr-maintenance',
  templateUrl: './maintenance.component.html',
})
export class MaintenanceComponent implements OnInit {
  constructor(private router: Router, @Inject(WINDOW) private window: ExtendedWindow) {}

  ngOnInit() {
    // Replaces 'maintenance' optimizely flag
    // TODO: Add proper maintenance flag toggling from API
    const maintenanceEnabled = false;
    if (maintenanceEnabled) {
      this.router.navigate(['/register', 'login']);
    }
  }

  refreshApp() {
    this.window.location.reload();
  }
}
