import { Component, OnInit } from '@angular/core';
import { ModalService } from '@pushdr/common/overlay';
import { GPPartnershipType, GPShareStatus, GPSearchResult } from '@pushdr/common/types';
import { FlowService } from '../../services/funnel-flow/flow.service';
import { ApiPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { AnalyticsService } from '@pushdr/common/data-access/analytics';
import { map } from 'rxjs/operators';
import { AccountProfileService } from '@pushdr/patientapp/common/utils';
import { Router } from '@angular/router';
import { private_registration } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';

@Component({
  selector: 'pushdr-surgery-selector',
  templateUrl: './surgery-selector.component.html',
  styleUrls: ['surgery-selector.scss'],
})
export class SurgerySelectorComponent implements OnInit {
  showDefaultGPSelect = true;
  corruptedSurgeryState = false;
  enableSelectNone = false;
  isLoaderVisible = false;

  constructor(
    private patientApi: ApiPatientService,
    private account: AccountProfileService,
    private modal: ModalService,
    private flow: FlowService,
    private analytics: AnalyticsService,
    private router: Router,
    private envProxy: EnvironmentProxyService
  ) {}

  ngOnInit() {
    if (this.account.isNHS) {
      this.patientApi.account
        .getGP()
        .pipe(map(gp => !!gp.Id && !!gp.PartnershipType))
        .subscribe({
          next: hasValidSurgery => {
            if (hasValidSurgery) {
              this.flow.next();
            } else {
              this.corruptedSurgeryState = true;
            }
          },
        });
    } else {
      this.patientApi.account.getGP().subscribe(gp => {
        if (gp.Id) {
          if (gp.PartnershipType !== GPPartnershipType.NHS_PARTNER) {
            this.continueAsPrivate();
          } else {
            this.enableSelectNone = false;
          }
        }
      });
    }
  }

  selectNone() {
    if (this.isLoaderVisible) return;
    this.showLoader();
    this.patientApi.account.updateGP('0', GPShareStatus.NO_NHS_GP).subscribe(
      res => {
        this.directToSelected();
      },
      err => this.modal.error(err),
      () => this.closeModal()
    );
  }

  selectSurgery(selectedSurgery: GPSearchResult) {
    if (this.isLoaderVisible) return;
    const shareStatus: GPShareStatus =
      selectedSurgery.PartnershipType === GPPartnershipType.NOT_A_PARTNER
        ? GPShareStatus.TBC
        : GPShareStatus.DO_NOT_SHARE;

    this.showLoader();
    this.patientApi.account.updateGP(`${selectedSurgery.Id}`, shareStatus).subscribe(
      () => {
        const isNHS = selectedSurgery.PartnershipType === GPPartnershipType.NHS_PARTNER;
        if (isNHS) {
          this.refreshCustomerAndContinue();
        } else {
          window.location.href = this.envProxy.environment.patient.pdPrivateWebsiteBookingPage;
        }
      },
      error => {
        this.modal.error(error);
      }
    );
  }

  readyToSelect() {
    this.showModalForNHSUsersInBadState();
  }

  private showModalForNHSUsersInBadState() {
    if (this.corruptedSurgeryState) {
      this.modal.acknowledge(
        'Reselect your surgery',
        'It appears we need reminding which NHS surgery you are registered with, apologies for the inconvenience. If you have any problems please contact customer services'
      );
    }
  }

  private continueAsPrivate() {
    // Replaces optimizely 'private_registration' flag
    // TODO: Add proper config for 'private_registration' flag
    const privateRegistrationConfig = private_registration;
    if (privateRegistrationConfig.enabled) {
      this.refreshCustomerAndContinue();
    } else {
      this.directToSelected();
    }
  }

  private directToSelected() {
    this.closeModal();
    this.router.navigate(['verification', 'surgery', 'selected']);
  }

  private refreshCustomerAndContinue() {
    this.account.resolve().then(() => {
      this.closeModal();
      this.flow.next();
    });
  }

  private showLoader() {
    this.modal.showLoader();
    this.isLoaderVisible = true;
  }

  private closeModal() {
    this.modal.close();
    this.isLoaderVisible = false;
  }
}
