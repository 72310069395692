<section class="c-form-part" trackView="reset password">
  <h1 class="c-form-part__title">Reset your password</h1>
  <p>To protect you and your information we use a two-step verification process.</p>
  <p>Please enter the 4 digit code sent to your mobile below and press 'Reset Password'</p>
  <form
    novalidate
    #f="ngForm"
    (ngSubmit)="f.valid && confirmpassword.value === password.value && submit()"
    class="c-form-part__form">
    <div
      class="c-form-part__field-wrap"
      [class.c-form-part__entry--is-invalid]="code.invalid && f.submitted">
      <label class="c-form-part__field-label" for="ipt-strVerificationCode">Code</label>
      <input
        type="text"
        [(ngModel)]="strVerificationCode"
        #code="ngModel"
        name="code"
        class="c-form-part__field"
        id="ipt-strVerificationCode"
        pattern="\d{4}"
        aria-required="true"
        required
        autocomplete="off" />
      <p class="c-form-part__error-message" id="err-msg-required-code" *ngIf="code?.errors">
        Please enter the 4 digit verification code in your text message.
      </p>
    </div>

    <div
      class="c-form-part__field-wrap"
      [class.c-form-part__entry--is-invalid]="errors?.length || (password.invalid && f.submitted)">
      <label class="c-form-part__field-label" for="ipt-strPassword">New Password</label>
      <input
        type="password"
        [(ngModel)]="strPassword"
        #password="ngModel"
        name="password"
        class="c-form-part__field"
        id="ipt-strPassword"
        aria-required="true"
        required
        (keydown)="errors = []"
        autocomplete="off" />
      <p
        class="c-form-part__error-message"
        id="err-msg-required-strPassword"
        *ngIf="password?.errors?.required">
        Please enter a new password.
      </p>
      <p *ngFor="let error of errors" class="c-form-part__error-message">{{ error }}</p>
      <ng-container *ngIf="!f.submitted">
        <p
          class="u-mb8@xs c-form-part__field-tip"
          *ngFor="let condition of (this.rules$ | async)?.conditionsToMeet; let last = last"
          [class.u-mb32@xs]="last">
          {{ condition }}
        </p>
      </ng-container>
    </div>

    <div
      class="c-form-part__field-wrap"
      [class.c-form-part__entry--is-invalid]="
        confirmpassword.value !== password.value && confirmpassword.dirty && password.dirty
      ">
      <label class="c-form-part__field-label" for="ipt-strPasswordConfirmed"
        >Confirm Password</label
      >
      <input
        type="password"
        ngModel
        #confirmpassword="ngModel"
        name="confirmpassword"
        class="c-form-part__field"
        id="ipt-strPasswordConfirmed"
        aria-required="true"
        required
        autocomplete="off" />
      <p
        class="c-form-part__error-message"
        id="err-msg-required-strPasswordConfirmed"
        *ngIf="confirmpassword.dirty && confirmpassword?.errors?.required">
        Please confirm your password.
      </p>
      <p
        class="c-form-part__error-message"
        *ngIf="confirmpassword.dirty && password.dirty && confirmpassword.value !== password.value">
        You've not entered the same password in the confirmation field, please check it.
      </p>
    </div>

    <button
      class="c-btn c-btn--primary c-form-part__btn"
      value="Sign in"
      trackClick="password reset">
      Reset Password
    </button>
    <button
      (click)="resendCode()"
      type="button"
      class="c-btn c-btn--default c-form-part__btn"
      value="Log in"
      trackClick="request code 2">
      Resend Code
    </button>
  </form>
</section>
