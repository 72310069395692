<div *ngIf="acceptance$ | async as acceptance; else loading" class="m-auto max-w-[367px]">
  <ng-container [ngSwitch]="acceptance.Type">
    <pushdr-consent-governance
      *ngSwitchCase="AcceptanceType.GOVERNANCE"
      [acceptance]="acceptance"
      (pdrAccepted)="onAccept(acceptance)"
      (pdrRejected)="onDecline(acceptance)"></pushdr-consent-governance>
    <pushdr-consent-non-governance
      *ngSwitchDefault
      [acceptance]="acceptance"
      (pdrAccepted)="onAccept(acceptance)"></pushdr-consent-non-governance>
  </ng-container>
</div>

<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>
