import { Funnel } from '../services/funnel-flow/flow-types';

export const OneAppVarient: Funnel = {
  config: {
    showSocialSignButtons: true,
  },
  gates: [
    {
      name: 'registration',
      pages: [
        {
          name: 'Sign Up',
          link: '/register/signup',
        },
        {
          name: 'Log In',
          link: '/register/login',
          locked: true,
        },
        {
          name: 'Get Reset Code',
          link: '/register/getcode',
          locked: true,
        },
        {
          name: 'Reset Password',
          link: '/register/resetpassword',
          locked: true,
        },
        {
          name: 'Nhs Authorisation',
          link: '/auth',
          locked: true,
        },
        {
          name: 'Set Mobile',
          link: '/register/mobile',
        },
        {
          name: 'Set Password',
          link: '/register/password',
        },
        {
          name: 'Agree Terms',
          link: '/register/terms',
          submit: {
            registration: true,
          },
        },
      ],
    },
    {
      name: 'patient',
      pages: [
        {
          name: 'Terms Checker',
          link: '/patient/terms?checking=1',
        },
        {
          name: 'Name',
          link: '/patient/name',
        },
        {
          name: 'DOB',
          link: '/patient/dob',
        },
        {
          name: 'Gender',
          link: '/patient/gender',
        },
        {
          name: 'Address',
          link: '/patient/address',
          submit: {
            patient: true,
          },
        },
      ],
    },
    {
      name: 'verification',
      pages: [
        {
          name: 'Terms Checker',
          link: '/verification/terms?checking=1',
        },
        {
          name: 'Verify Mobile',
          link: '/verification/verifymobile',
        },
        {
          name: 'Select Surgery',
          link: '/verification/surgery',
        },
        {
          name: 'Private Consultation Details',
          link: '/verification/surgery/private/redirect-to-success',
          locked: true,
        },
        {
          name: 'Funnel Ending',
          link: '/verification/success',
        },
      ],
    },
  ],
};
