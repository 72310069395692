<section class="c-form-part" trackView="mobile">
  <h1 class="c-form-part__title" pdFocus="true">
    Confirm your mobile number
    <span class="u-sr-only">this page contains a mobile input form field</span>
  </h1>
  <form novalidate #f="ngForm" (ngSubmit)="f.valid && submit()" class="c-form-part__form">
    <div
      class="c-form-part__field-wrap"
      [class.c-form-part__entry--is-invalid]="mobile.invalid && f.submitted">
      <label class="c-form-part__field-label" for="ipt-tel">Mobile number</label>
      <input
        [(ngModel)]="Mobile"
        #mobile="ngModel"
        name="Mobile"
        class="c-form-part__field"
        id="ipt-tel"
        required
        pattern="^\+?(07[\d]{8,12}|447[\d]{7,11})\s*"
        required
        aria-required="true"
        type="tel"
        autocomplete="tel" />
      <p
        class="c-form-part__error-message"
        id="err-msg-required"
        *ngIf="mobile?.errors?.required"
        role="alert">
        A UK mobile number is required to sign up.
      </p>
      <p
        class="c-form-part__error-message"
        id="err-msg-pattern"
        *ngIf="mobile?.errors?.pattern"
        role="alert">
        Please add a valid UK mobile number.
      </p>
    </div>
    <button
      class="c-btn c-btn--primary c-form-part__btn"
      type="submit"
      trackClick="next"
      aria-label="Click next to continue">
      Next
    </button>
  </form>
</section>
