export const GATE_REGISTRATION = 'registration';
export const GATE_PATIENT = 'patient';
export const GATE_VERIFICATION = 'verification';

export interface Funnel {
  config: FunnelConfig;
  gates: Gate[];
}

export interface Gate {
  name: string;
  pages: Page[];
}

export interface FunnelConfig {
  showSocialSignButtons: boolean;
}

export interface Page {
  name: string;
  link: string;
  locked?: boolean;
  watch?: string;
  submit?: Submit;
  // eslint-disable-next-line
  model?: any;
  gate?: Gate;
}

interface Submit {
  patient?: boolean;
  registration?: boolean;
  verify?: boolean;
}

export class Verification {}
