import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AcceptanceType, CustomerAcceptance } from '@pushdr/consent/api';
import { AcceptanceService } from './acceptance.service';
import { ConsentService } from './consent.service';

@Injectable({
  providedIn: 'root',
})
export class ConsentApprovalGuard implements CanActivate {
  constructor(
    private router: Router,
    private acceptance: AcceptanceService,
    private consent: ConsentService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const redirectToOnFail = route.data.redirectToOnFail ?? '..';
    const acknowledgeRequired = route.data.acknowledgeRequired ?? true;

    const createRedirectUrlTree = () =>
      this.router.createUrlTree(redirectToOnFail, { queryParams: route.queryParams });

    return this.acceptance.getAcceptanceType().pipe(
      switchMap(type => this.consent.getConsent(type)),
      map(consent => this.isConsentAccepted(consent, acknowledgeRequired)),
      map(canActivate => (canActivate ? true : createRedirectUrlTree()))
    );
  }

  private isConsentAccepted(consent: CustomerAcceptance, acknowledgeRequired: boolean): boolean {
    if (consent.Type === AcceptanceType.GOVERNANCE && acknowledgeRequired) {
      return this.consent.consentAcknowledged && !!consent.Accepted;
    }
    return !!consent.Accepted;
  }
}
