import { Component } from '@angular/core';
import { FlowStateService } from '../../services/funnel-flow/flow-state.service';
import { FlowService } from '../../services/funnel-flow/flow.service';

@Component({
  selector: 'pushdr-gender',
  templateUrl: './gender.component.html',
})
export class GenderComponent {
  constructor(private flow: FlowService, private state: FlowStateService) {}

  submit(Gender: number) {
    this.state.updatePatientModel({
      Gender,
    });
    this.flow.next();
  }
}
