import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'pushdr-nhs-authorisation-error-modal',
  templateUrl: './nhs-authorisation-error-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NhsAuthorisationErrorModalComponent extends SimpleModalComponent<null, null> {
  message = '';

  constructor() {
    super();
  }
}
