import { Injectable } from '@angular/core';
import { AppointmentAvailability, GetAppointmentAvailabilityForDay } from '@pushdr/common/types';
import {
  ApiAvailabilityStateObj,
  ApiNHSPatientService,
  Availability,
} from '@pushdr/patientapp/common/data-access/patient-api';
import { throwError, of, Observable } from 'rxjs';
import { map, take, mergeMap, catchError, tap } from 'rxjs/operators';
import { DatesService, DATETIME_FMT } from '@pushdr/common/utils';
import * as moment from 'moment';
import { Moment } from 'moment';
import { PatientMarketplaceService } from '@pushdr/patientapp/common/services';
import { AnalyticsService } from '@pushdr/common/data-access/analytics';
@Injectable({
  providedIn: 'root',
})
export class BookingAvailabilityService {
  constructor(
    private api: ApiNHSPatientService,
    private marketplace: PatientMarketplaceService,
    private analytics: AnalyticsService,
    private dates: DatesService
  ) {}

  getAppointments$(): Observable<GetAppointmentAvailabilityForDay[]> {
    return this.getAvailableAppointments$(moment(), moment().add(1, 'weeks'));
  }

  getAvailabilityState$(): Observable<ApiAvailabilityStateObj> {
    return this.api.availabiliity.getAvailabilityState(this.marketplace.getPartnerId());
  }

  getDaysWithAvailability$() {
    return this.getAppointments$().pipe(
      map(appointments => {
        const availableDays = Array.from(new Set(appointments.map(a => a.strDate)))
          .map(strDate => appointments.find(a => a.strDate === strDate))
          .map(a => ({
            text: this.dates.getDayFromDateString(a.strDate),
            value: a.strDate,
          }));
        return availableDays;
      })
    );
  }

  checkForNewAppointmentAvailability() {
    return this.getAvailableAppointments$(moment(), moment().add(1, 'weeks')).pipe(
      catchError(() => of([])),
      map(
        appointments =>
          new AppointmentAvailability({
            Availability: appointments,
          })
      )
    );
  }

  private trackAnalyticalEvents(partnerId: string, appointments: Availability[]) {
    if (!appointments.length) {
      return this.analytics.trackEvent({
        action: 'availability_ui_reached',
        properties: {
          reason: 'no availability',
          partnerId,
        },
      });
    }

    return this.analytics.trackEvent({
      action: 'availability_ui_reached',
      properties: {
        number_of_slots: appointments.length,
        same_day: !!appointments.find(x => moment().diff(x.StartUtc, 'days') === 0),
        partnerId,
      },
    });
  }

  private getAvailableAppointments$(
    startUTC = moment.utc(),
    endUTC?: Moment
  ): Observable<GetAppointmentAvailabilityForDay[]> {
    endUTC = endUTC || startUTC;
    const partnerId = this.marketplace.getPartnerId();
    const clinicianType = this.marketplace.getClinicianType();

    return this.api.availabiliity.getAppointments(partnerId, startUTC, endUTC, clinicianType).pipe(
      tap(appointments => this.trackAnalyticalEvents(partnerId, appointments)),
      mergeMap(appointments => {
        if (!appointments.length) return throwError(new Error('No availability'));

        const mappedAppointments = appointments.map(r => {
          const localTime = moment(moment.utc(r.StartUtc, 'YYYY-MM-DD HH:mm:ss')).local();
          const appointment: GetAppointmentAvailabilityForDay = {
            strDate: localTime.format(DATETIME_FMT.DATE_SLASH),
            strDateTime: localTime.format(DATETIME_FMT.DATE_TIME_SLASH),
            strEndDateTime: moment(localTime)
              .add(10, 'minutes')
              .format(DATETIME_FMT.DATE_TIME_SLASH),
            strFormattedDateString: localTime.format(DATETIME_FMT.FRIENDLY_DATE_TIME),
            strDay: localTime.format(DATETIME_FMT.DATE_TIME_SLASH),
            strAmPmStart: localTime.format(DATETIME_FMT.TIME12HR),
            strAmPmEnd: moment(localTime).add(10, 'minutes').format(DATETIME_FMT.TIME12HR),
            partnerId: r.AppointmentSlots[0]?.PartnerId,
            price: r.AppointmentSlots[0]?.OrderDetails?.AppointmentPrice,
            totalPrice: r.AppointmentSlots[0]?.Price,
            discount: r.AppointmentSlots[0]?.OrderDetails?.Discount,
            clinicianType: r.AppointmentSlots[0]?.ClinicianType,
            startAppointmentTime: localTime.format('hh:mma'),
            startAppointmentDate: localTime.format('dddd Do MMMM'),
          };
          return appointment;
        });

        return of(mappedAppointments);
      }),
      take(1)
    );
  }
}
