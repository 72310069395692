<div class="c-modal c-modal--is-visible">
  <div class="c-modal__wrap c-modal__wrap--40">
    <div class="modal__body">
      <p class="mb-4">
        Unfortunately, recording the consultation is necessary to ensure we maintain the best
        possible standards of care and clinical governance, therefore you will be unable to proceed
        with booking an appointment.
      </p>
      <p class="mb-0">
        Please contact your surgery to arrange an appointment directly or alternatively, select the
        ‘I’ve changed my mind’ option to return to the previous consent screen.
      </p>
    </div>

    <div class="modal__footer">
      <button (click)="done(true)" class="c-btn c-btn--primary">I’ve changed my mind</button>
      <button (click)="done(false)" class="c-btn c-btn--primary c-btn--white-bg">Go to home</button>
    </div>
  </div>
</div>
