import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { FlowService } from '../../services/funnel-flow/flow.service';

@Component({
  selector: 'pushdr-progress',
  templateUrl: './progress.component.html',
})
export class ProgressComponent implements OnInit {
  constructor(private router: Router, private flow: FlowService) {}

  percentageComplete$;

  ngOnInit() {
    this.percentageComplete$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => this.flow.getPercentageComplete)
    );
  }
}
