export enum AcceptanceGroup {
  NOT_SPECIFIED = 0,
  MARKETING = 1,
  NHS = 2,
}

export enum AcceptanceGroupName {
  NOT_SPECIFIED = 'not-specified',
  MARKETING = 'marketing',
  NHS = 'nhs',
}

export enum AcceptanceType {
  NOT_SPECIFIED,
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
  MARKETING_SUBSCRIPTION,
  MEDICAL_RECORDS_CONSENT,
  GOVERNANCE,
}

export enum AcceptanceTypeName {
  NOT_SPECIFIED = 'NotSpecified',
  TERMS_AND_CONDITIONS = 'TermsAndConditions',
  PRIVACY_POLICY = 'PrivacyPolicy',
  MARKETING_SUBSCRIPTION = 'MarketingSubscription',
  MEDICAL_RECORDS_CONSENT = 'MedicalRecordsConsent',
  GOVERNANCE = 'Governance',
}

export interface Acceptance {
  Content?: string;
  Created?: string;
  Group: AcceptanceGroup;
  Type: AcceptanceType;
  Version: number;
  Mandatory: boolean;
  Name?: string;
  CallToAction?: string;
}

export interface CustomerAcceptance {
  Accepted?: boolean;
  DateStamp?: string;
  Group: AcceptanceGroup;
  Type: AcceptanceType;
  Version: number;
  Mandatory: boolean;
}

export function mapAcceptanceTypeToName(type: AcceptanceType): AcceptanceTypeName {
  const names = Object.values(AcceptanceTypeName);
  return names[type];
}

export function mapAcceptanceGroupToName(group: AcceptanceGroup): AcceptanceGroupName {
  const names = Object.values(AcceptanceGroupName);
  return names[group];
}
