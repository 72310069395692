<section class="c-form-part" trackView="gender">
  <h1 class="c-form-part__title" pdFocus="true">
    What was your gender assigned at birth?
    <span class="u-sr-only">
      , on this screen you will select either Male or Female by selecting the button respectively
    </span>
  </h1>
  <p tabindex="0">Please ensure this matches your NHS medical records</p>
  <div class="c-form-part__dbl-btn-wrap">
    <button
      (click)="submit(1)"
      class="c-btn c-btn--primary c-form-part__btn"
      aria-label="select Male and continue"
      value="Choose option"
      trackClick="next">
      Male
    </button>
    <button
      (click)="submit(2)"
      class="c-btn c-btn--primary c-form-part__btn"
      aria-label="select Female and continue"
      value="Choose option"
      trackClick="next">
      Female
    </button>
  </div>
</section>
