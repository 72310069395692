import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pushdr-what-is-nhs-login',
  styleUrls: ['./what-is-nhs-login.component.scss'],
  templateUrl: './what-is-nhs-login.component.html',
})
export class WhatIsNHSLoginComponent {
  constructor(private router: Router) {}

  goBackToSignUp() {
    this.router.navigate(['register', 'signup']);
  }
}
