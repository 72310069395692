import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentProxyService } from '@pushdr/environment';
import {
  RestHttpHeaderService,
  RestClient,
  RestErrorParserService,
} from '@pushdr/common/data-access/rest-http-core';
import { Observable } from 'rxjs';

export interface AccessQuestionnaireQuestion {
  Id: string;
  Question: string;
  ExampleResponse: string;
  Order: number;
}
export interface AccessQuestionnaire {
  Id: string;
  Questions: AccessQuestionnaireQuestion[];
}
export interface AccessQuestionnaireResponse {
  Id: string;
  Responses: { QuestionText: string; QuestionResponse: string }[];
}

@Injectable({
  providedIn: 'root',
})
export class AccessQuestionnaireAPIService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI;
  }

  patientQuestionnaire(response: AccessQuestionnaireResponse) {
    return this.post('/AccessQuestionnaire', response, this.headerService.headers());
  }

  getQuestionnaire(): Observable<AccessQuestionnaire> {
    return this.get('/AccessQuestionnaire', {}, this.headerService.headers(), 1);
  }
}
