import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ModalService } from '@pushdr/common/overlay';
import { map, tap } from 'rxjs';

import { SpinnerLoaderModule } from '@pushdr/common/components';
import { ApiAccountPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';

@Component({
  selector: 'pushdr-home-nhs-welcome',
  templateUrl: './home-nhs-welcome.component.html',
  styleUrls: ['./home-nhs-welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule, SpinnerLoaderModule],
})
export class HomeNhsWelcomeComponent {
  surgeryName$ = this.accountApi.getGP().pipe(
    tap({ error: err => this.modal.error(err?.message ?? 'Failed to load GP details') }),
    map(surgery => surgery?.Name)
  );

  constructor(private modal: ModalService, private accountApi: ApiAccountPatientService) {}
}
