import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RestHttpHeaderService } from '@pushdr/common/data-access/rest-http-core';
import { PDServerError } from '@pushdr/common/types';
import { StorageService, TokenService } from '@pushdr/common/utils';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { ModalService } from '@pushdr/common/overlay';
import { debounceTime, map } from 'rxjs/operators';
import { FlowService } from '../../services/funnel-flow/flow.service';
import { EncryptedUserService } from '@pushdr/patientapp/common/utils';

@Component({
  selector: 'pushdr-verifymobile',
  templateUrl: './verifymobile.component.html',
})
export class VerifyMobileComponent implements OnInit {
  sending = false;
  mobileMasked = '';
  codeCookie = 'pdr_cookiethumper';
  private isLoaderVisible = false;

  constructor(
    private flow: FlowService,
    private userService: EncryptedUserService,
    private api: ApiNHSPatientService,
    private header: RestHttpHeaderService,
    private storage: StorageService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private modal: ModalService,
    private token: TokenService
  ) {}

  ngOnInit() {
    this.showLoader({ bottomText: 'Loading' });
    this.mobileMasked = '+44 (0) 7XXXXXX' + this.mobileEnding();
    this.checkUserIsReadyForThisJelly()
      .then(code => {
        if (code) {
          this.submitCode(code);
        } else {
          this.closeModal();
          this.sendSms(false);
        }
      })
      .catch(() => {
        this.closeModal();
        this.router.navigate(['/register', 'login'], { replaceUrl: true });
      });
  }

  checkUserIsReadyForThisJelly(): Promise<string> {
    return new Promise((res, rej) => {
      this.activeRoute.queryParams
        .pipe(
          debounceTime(1000),
          map(params => params.code)
        )
        .subscribe(
          code => {
            if (code) {
              this.storage.set(this.codeCookie, code, 0, 0, null);
            } else {
              code = this.storage.get(this.codeCookie, false) || '';
            }
            if (!this.header.hasHeaders()) {
              rej();
            } else {
              res(code);
            }
          },
          err => {
            res('');
          }
        );
    });
  }

  mobileEnding() {
    if (!this.userService.user || !this.userService.user.Mobile) {
      return 'your mobile number';
    } else {
      let ending = this.userService.user.Mobile || '';
      ending = ending.replace(/.(?=.{4,}$)/g, '');
      return ending || 'XXXX';
    }
  }

  submit(f: NgForm) {
    if (this.isLoaderVisible) return;
    this.submitCode(f.value.code.trim());
  }

  private submitCode(code: string) {
    this.showLoader({ bottomText: 'Verifying' });
    return this.api.authentication.verification(code).subscribe({
      next: bearerToken => {
        this.token.set(bearerToken);
        setTimeout(() => {
          this.closeModal();
          //delete the temp cookie and user cookie
          this.storage.delete(this.codeCookie);
          this.userService.reset();
          this.flow.next();
        }, 10);
      },
      error: err => {
        this.modal.error(this.api.errorMessages.authentication.verification(err).message);
        this.isLoaderVisible = false;
      },
    });
  }

  sendSms(retry = true) {
    if (this.isLoaderVisible) return;
    this.showLoader();
    this.api.authentication.sendVerificationCode().subscribe(
      () => {
        this.closeModal();
        if (retry) {
          this.modal.acknowledge('Sent', 'A code is on its way');
        }
      },
      (err: PDServerError) => {
        if (err.message === 'User already verified') {
          this.flow.next();
        } else {
          const { header, message } =
            this.api.errorMessages.authentication.sendAuthenticationCode(err);
          this.modal.acknowledge(header, message);
        }
      }
    );
  }

  showLoader(cfg?: { header?: string; bottomText?: string }) {
    this.modal.showLoader(cfg);
    this.isLoaderVisible = true;
  }

  closeModal() {
    this.modal.close();
    this.isLoaderVisible = false;
  }
}
