import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TermsComponent } from '../common/terms/terms.component';
import { VerifyMobileComponent } from './verifymobile/verifymobile.component';
import { SurgerySelectorComponent } from './surgery-selector/surgery-selector.component';
import { VerificationSuccessComponent } from './verificationsuccess/verificationsuccess.component';
import { SurgerySelectedComponent } from '../common/surgery/surgery-selected/surgery-selected.component';
import { HasCompletedProfileGateGuard } from './has-completed-profile.guard';
import { SurgeryNotifyComponent } from '../common/surgery/surgery-notify/surgery-notify.component';

const routes: Routes = [
  {
    path: 'verification',
    redirectTo: '/verification/terms',
    pathMatch: 'full',
  },
  {
    path: 'verification',
    canActivate: [HasCompletedProfileGateGuard],
    children: [
      {
        path: 'terms',
        component: TermsComponent,
      },
      {
        path: 'verifymobile',
        component: VerifyMobileComponent,
      },
      {
        path: 'surgery',
        component: SurgerySelectorComponent,
      },
      {
        path: 'surgery/selected',
        component: SurgerySelectedComponent,
      },
      {
        path: 'surgery/notify',
        component: SurgeryNotifyComponent,
      },
      {
        path: 'success',
        component: VerificationSuccessComponent,
      },
      {
        path: 'success/:partner',
        component: VerificationSuccessComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [HasCompletedProfileGateGuard],
})
export class VerificationRoutingModule {}
