import { Component } from '@angular/core';
import { CustomerAddress, SelectListAddress } from '@pushdr/common/types';
import { FlowStateService } from '../../../services/funnel-flow/flow-state.service';
import { FlowService } from '../../../services/funnel-flow/flow.service';
import { addressFactory } from '../common/utils';
import { validAddress } from '@pushdr/patientapp/common/components';

@Component({
  selector: 'pushdr-address-search',
  templateUrl: './address-search.component.html',
})
export class AddressSearchComponent {
  postCode = '';
  address: CustomerAddress;
  showManual = false;
  results: SelectListAddress[] = [];
  submitted = false;

  constructor(private flow: FlowService, private state: FlowStateService) {}

  revealManualForm() {
    this.address = addressFactory({
      PostCode: this.postCode,
      IsDefault: true,
    });
    this.showManual = true;
  }

  setAddress(event: CustomerAddress) {
    this.address = event;
    this.submitted = true;
    if (!event) return;
    if (validAddress(this.address)) {
      this.state.updatePatientModel({
        Addresses: [this.address],
      });
      this.flow.next();
    }
  }
}
