import { buildAddressLabel } from '@pushdr/common/utils';

export const addressFactory = defaults => {
  return Object.assign(
    {
      Id: '',
      AddressLine1: '',
      AddressLine2: '',
      AddressLine3: '',
      AddressLine4: '',
      AddressLine5: '',
      PostTown: '',
      County: '',
      PostCode: '',
      IsDefault: false,
    },
    defaults
  );
};

export const prepareAddressesForNgSelect = data => {
  return data.map(a => {
    return {
      id: a.Id,
      original: a,
      label: buildAddressLabel(
        a,
        [
          ['AddressLine1', 'AddressLine2', 'AddressLine3', 'AddressLine4', 'AddressLine5'],
          'PostTown',
          'PostCode',
        ],
        ', ',
        ['PostCode']
      ),
    };
  });
};
