import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { EnvironmentProxyService } from '@pushdr/environment';
import { map, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
export interface Signpost {
  Title: string;
  QuestionnaireId: string;
}

@Injectable({
  providedIn: 'root',
})
export class SignpostingApiService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.patient.nhsAPI + '/signpost';
  }

  getSignposts(customerId?: string): Observable<Signpost[]> {
    const params = {};
    if (customerId) {
      params['customerId'] = customerId;
    }
    return this.get('', params, this.headerService.bearerTokenHeaders(), 1).pipe(
      map(res => res?.Signposts)
    );
  }
}
