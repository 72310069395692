import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  PasswordStrengthConditions,
  PasswordStrengthConditionsNotMet,
  PDServerError,
  ResetPasswordRequest,
} from '@pushdr/common/types';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { ModalService } from '@pushdr/common/overlay';
import { Observable } from 'rxjs';
import { FlowStateService } from '../../../services/funnel-flow/flow-state.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'pushdr-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private api: ApiNHSPatientService,
    private modal: ModalService,
    private router: Router,
    private state: FlowStateService
  ) {}

  strVerificationCode = '';
  strPassword = '';
  errors = [];
  rules$: Observable<PasswordStrengthConditions>;

  ngOnInit() {
    this.rules$ = this.api.validation.passwordConditions();
  }

  submit() {
    this.modal.showLoader();
    this.api.validation.passwordStrength(this.strPassword).subscribe(
      res => {
        this.resetPassword();
      },
      (err: PDServerError) => {
        this.modal.close();
        this.errors = (err.original.error as PasswordStrengthConditionsNotMet).conditionsNotMet;
      }
    );
  }

  resetPassword() {
    const resetRequest: ResetPasswordRequest = {
      Username: this.state.registration.Email,
      Mobile: this.state.registration.Mobile,
      VerificationCode: this.strVerificationCode,
      Password: this.strPassword,
    };

    this.api.authentication.resetPassword(resetRequest).subscribe(
      response => {
        // TODO: btn text should be 'Sign In'
        this.modal
          .acknowledge('Password Reset', 'Your password has been reset')
          .pipe(take(1))
          .subscribe(d => {
            this.router.navigate(['/register/login']);
            this.modal.close();
          });
      },
      error => {
        const { message } = this.api.errorMessages.authentication.resetPassword(error);
        this.modal.error(message);
      }
    );
  }

  resendCode() {
    this.modal.showLoader();
    this.api.authentication
      .forgotPassword(this.state.registration.Email, this.state.registration.Mobile)
      .subscribe(
        response => {
          this.modal.acknowledge(
            'Sent',
            `A code has been sent to ${this.state.registration.Mobile}`
          );
        },
        error => {
          const { message } = this.api.errorMessages.authentication.forgotPassword(error);
          this.modal.error(message);
        }
      );
  }
}
