import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { GPSearchResult } from '@pushdr/common/types';

@Component({
  selector: 'pushdr-surgery-listview',
  templateUrl: './surgery-listview.component.html',
  styleUrls: ['./surgery-listview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurgeryListviewComponent {
  @Input() surgeries: GPSearchResult[];
  @Output() selectSurgery = new EventEmitter<GPSearchResult>();

  @ViewChildren('surgeryItem') surgeriesItems: QueryList<any>;

  ngAfterViewInit() {
    setTimeout(() => {
      this.surgeriesItems.first?.nativeElement.focus();
    }, 0);
  }
}
