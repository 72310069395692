import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { FlowStateService } from '../services/funnel-flow/flow-state.service';

@Injectable()
export class IsSigningUpGuard implements CanActivate {
  constructor(private state: FlowStateService, private router: Router) {}

  canActivate(): boolean {
    if (this.state.isSigningUp()) {
      return true;
    } else {
      this.router.navigate(['/register', 'signup'], { replaceUrl: true });
      return false;
    }
  }
}
