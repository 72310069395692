import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnalyticsService } from '@pushdr/common/data-access/analytics';
import { ModalService } from '@pushdr/common/overlay';
import { Acceptance } from '@pushdr/consent/api';
import { RecordingAcceptationModalComponent } from '@pushdr/consent/components';

@Component({
  selector: 'pushdr-consent-governance',
  templateUrl: './consent-governance.component.html',
})
export class ConsentGovernanceComponent {
  @Input() acceptance!: Acceptance;
  @Output() pdrAccepted = new EventEmitter<void>();
  @Output() pdrRejected = new EventEmitter<void>();

  get termsUrl(): string {
    return this.acceptance?.Content ?? '';
  }

  constructor(private modal: ModalService, private analytics: AnalyticsService) {}

  onAccept(): void {
    this.analytics.trackEvent({
      action: 'governance_acceptance_consented',
      category: 'governance',
    });

    this.pdrAccepted.emit();
  }

  onDecline(): void {
    this.analytics.trackEvent({
      action: 'governance_acceptance_declined',
      category: 'governance',
    });

    this.modal.showCustom(RecordingAcceptationModalComponent).subscribe({
      next: result => this.handleDeclineModalResult(result),
    });
  }

  private handleDeclineModalResult(modalResult?: boolean) {
    if (modalResult === false) {
      this.analytics.trackEvent({
        action: 'navigated_home_after_governance_decline',
        category: 'governance',
      });
      this.pdrRejected.emit();
    } else {
      this.analytics.trackEvent({
        action: 'changed_mind_after_governance_decline',
        category: 'governance',
      });
    }
  }
}
