import { Component, OnInit } from '@angular/core';
import {
  AcceptanceTypes,
  Acceptance,
  ExtendedCustomerAcceptance,
  SocialSignInKeys,
  PDServerError,
} from '@pushdr/common/types';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import {
  SignOutService,
  CustomerAcceptancesService,
  EncryptedUserService,
} from '@pushdr/patientapp/common/utils';
import { ModalService } from '@pushdr/common/overlay';
import { mergeMap, take } from 'rxjs/operators';
import {
  FlowStateService,
  SelectedSurgeryType,
} from '../../services/funnel-flow/flow-state.service';
import { FlowService } from '../../services/funnel-flow/flow.service';
import { Router } from '@angular/router';
import { IframeModalComponent } from '@pushdr/common/components';

@Component({
  selector: 'pushdr-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  Email: string = this.state.registration.Email;
  privacyPolicy: ExtendedCustomerAcceptance;
  termsPolicy: ExtendedCustomerAcceptance;
  marketingPolicy: ExtendedCustomerAcceptance = {} as ExtendedCustomerAcceptance;
  nhsSignInFeature = !this.isAPrivatePatientRegistering();

  constructor(
    private flow: FlowService,
    private state: FlowStateService,
    private api: ApiNHSPatientService,
    private modal: ModalService,
    private userService: EncryptedUserService,
    private acceptances: CustomerAcceptancesService,
    private signOutService: SignOutService,
    private router: Router
  ) {}

  ngOnInit() {
    this.flow.reset();
    this.state.reset();
    this.userService.reset();

    this.acceptances
      .getAcceptancesAsNewUser()
      .pipe(take(1))
      .subscribe(acceptances => {
        this.pluckSignUpPageAcceptances(acceptances);
      });
  }

  private pluckSignUpPageAcceptances(acceptances: ExtendedCustomerAcceptance[]) {
    this.privacyPolicy = acceptances.find(a => +a.Type === +AcceptanceTypes.PRIVACY_POLICY);
    this.termsPolicy = acceptances.find(a => +a.Type === +AcceptanceTypes.TERMS_AND_CONDITIONS);
    this.marketingPolicy = acceptances.find(
      a => +a.Type === +AcceptanceTypes.MARKETING_SUBSCRIPTION
    );
  }

  readPolicy(acceptance: Acceptance) {
    this.modal.showCustom(IframeModalComponent, {
      title: acceptance.Name,
      linkToDocument: acceptance.Content,
    });
  }

  private updateAcceptancesForUserSigningUp() {
    this.privacyPolicy.Accepted = true;
    this.termsPolicy.Accepted = true;

    this.state.updateRegistrationModel({
      Acceptances: [this.privacyPolicy, this.termsPolicy, this.marketingPolicy],
    });
  }

  submit() {
    this.modal.showLoader({ bottomText: 'Loading' });
    this.signOutService
      .flushUser()
      .pipe(mergeMap(() => this.api.validation.canRegisterEmail(this.Email)))
      .subscribe(
        () => {
          this.state.updateRegistrationModel({
            Email: this.Email,
            SocialSignIn: SocialSignInKeys.NONE,
          });
          this.flow.lock(['Log In']);
          this.updateAcceptancesForUserSigningUp();
          this.flow.next();
          this.modal.close();
        },
        (error: PDServerError) => {
          if (error.status === 409) this.gotoToSignIn();
          else this.modal.error(this.api.errorMessages.validation.canRegisterEmail(error).message);
        }
      );
  }

  gotoToSignIn() {
    this.state.updateRegistrationModel({
      Email: this.Email,
      Mobile: '',
      SocialSignIn: SocialSignInKeys.NONE,
    });
    this.modal.close();
    this.flow.gotoPage('Log In', { with: 'email' });
  }

  signUpWithNHS() {
    this.modal.close();
    this.flow.gotoPage('Log In', { with: 'email', signInWithNhs: true });
  }

  private isAPrivatePatientRegistering() {
    return !!(
      this.state.getSelectedSurgeryStore().surgeryType === SelectedSurgeryType.NON_PARTNERED ||
      this.state.getSelectedSurgeryStore().surgeryType === SelectedSurgeryType.NO_SURGERY
    );
  }
}
