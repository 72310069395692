import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpinnerLoaderModule } from '@pushdr/common/components';
import { ConsentComponentsModule } from '@pushdr/consent/components';
import { ConsentShellRoutingModule } from './consent-shell-routing.module';
import { ConsentReviewComponent } from './consent-review/consent-review.component';
import { ConsentGovernanceComponent } from './consent-governance/consent-governance.component';
import { ConsentNonGovernanceComponent } from './consent-non-governance/consent-non-governance.component';

@NgModule({
  imports: [CommonModule, SpinnerLoaderModule, ConsentComponentsModule, ConsentShellRoutingModule],
  declarations: [ConsentReviewComponent, ConsentGovernanceComponent, ConsentNonGovernanceComponent],
})
export class ConsentShellModule {}
