<section class="c-form-part" trackView="address">
  <h1 class="c-form-part__title" pdFocus="true">
    What’s your home address?
    <span class="u-sr-only">
      ,On this page you will enter your postcode, press search, then select your address from the
      dropdown. ,If your address was not there, you can enter using the manual address button
    </span>
  </h1>
  <p tabindex="0">Please ensure this address matches the one your GP surgery has on record.</p>

  <pushdr-home-address-search
    (addressSelected)="setAddress($event)"
    *ngIf="!showManual"></pushdr-home-address-search>

  <p *ngIf="!showManual" class="c-form-part__bold-text">
    Can't find your address?
    <button
      class="c-btn-anchor"
      data-cy="manual-reveal"
      (click)="revealManualForm()"
      trackClick="manual entry">
      Enter home address manually
    </button>
  </p>

  <pushdr-home-address-manual
    *ngIf="showManual"
    (manualAddressSubmitted)="setAddress($event)"
    [address]="address"></pushdr-home-address-manual>
</section>
