import { CustomerAddress } from './address';
import { GPShareStatus, GPPartnershipType } from '../legacy';

export enum Gender {
  MALE = 1,
  FEMALE = 2,
  UNSPECIFIED = 3,
}

export class Customer {
  AccountProfiles?: string[] = [];
  FirstName?: string = null;
  LastName?: string = null;
  DOB?: string = null;
  Gender?: Gender = null;
  ArmyEnlistDate?: string = null;
  Status?: number = null;
  PreviousNames?: CustomerPreviousName[] = [];
  Addresses?: CustomerAddress[] = [];
  GpShare? = GPShareStatus.DO_NOT_SHARE;
  Key?: string = null;
  ExternalId?: string = null;
  Email?: string;
  Mobile?: string;
}

export class PartnerInfo {
  Id: string;
  Name: string;
  ConsultationRecording: boolean;
  IsMultipleAppointmentEnabled: boolean;
}

export const genderIntToString = enumInt => {
  switch (enumInt) {
    case 1:
      return 'male';
    case 2:
      return 'female';
    default:
      return 'unspecified';
  }
};

export interface CustomerPreviousName {
  FirstName: string;
  LastName: string;
  ChangedDate: string;
}

export interface CustomerGPDetails {
  Id: number;
  OdsCode: string;
  Name: string;
  Telephone: string;
  Address: {
    Line1: string;
    Line2: string;
    Line3: string;
    Line4: string;
    Line5: string;
    Postcode: string;
  };
  Location: {
    DatumStandard: string;
    Latitude: number;
    Longitude: number;
  };
  PartnershipType: GPPartnershipType;
}

export const GPDetailsFactory = (
  ShareStatus: GPShareStatus = GPShareStatus.DO_NOT_SHARE,
  PartnershipType: GPPartnershipType = GPPartnershipType.NHS_PARTNER
) => {
  return {
    ShareStatus,
    Id: 7834,
    OdsCode: 'P82613',
    Name: 'Spring View Medical Centre',
    Telephone: '01204 578128',
    Address: {
      Line1: 'Spring View Medical Ctr.',
      Line2: 'Mytham Road',
      Line3: 'Little Lever',
      Line4: 'Bolton',
      Line5: null,
      Postcode: 'BL3 1HQ',
    },
    Location: { DatumStandard: 'WGS 1984', Latitude: 53.560836, Longitude: -2.369742 },
    PartnershipType,
  } as CustomerGPDetails;
};

export interface ConsultationStatus {
  CanConsult: boolean;
  ProfileComplete: boolean;
  KycCheckStatus: string;
  IdCheckStatus: string;
  CustomerStatus: string;
}
