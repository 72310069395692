<section class="c-form-part u-text-center" trackView="maintenance">
  <img
    class="u-mb16@xs"
    src="assets/images/icon-maintenance.svg"
    alt="maintenance work is underway" />
  <h1 class="c-form-part__title" pdFocus="true">We'll be right back</h1>
  <p>
    Maintenance work is underway, but we'll be back soon. Contact our customer experience team on
    <a href="tel:+443308084702">0330&nbsp;808&nbsp;4702</a>, or
    <a class="cursor-pointer" pushdrZendesk>start&nbsp;a&nbsp;live&nbsp;chat</a> for any questions
    in the meantime.
  </p>
  <button
    class="c-btn c-btn--primary"
    data-cy="btnCheckForUpdates"
    (click)="refreshApp()"
    trackClick="check for updates">
    Check for updates
  </button>
</section>
