<section class="c-form-part" trackView="password" *ngIf="rules$ | async as rules; else loading">
  <h2 class="c-form-part__title" pdFocus="true">Create your password</h2>
  <form (ngSubmit)="form.valid && submit(form)" class="c-form-part__form" [formGroup]="form">
    <pushdr-password-input [rules]="rules" [parent]="form"> </pushdr-password-input>

    <pushdr-password-valid-alert [parent]="form"></pushdr-password-valid-alert>

    <button
      [disabled]="!form.valid"
      class="c-btn c-btn--primary c-form-part__btn u-mt24@xs submit-password-choice"
      type="submit"
      trackClick="next"
      aria-label="Click next to continue"
      data-cy="password-next-btn">
      Next
    </button>
  </form>
</section>

<!--  Loader spinner for the page -->
<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>
