<section class="c-form-part" trackView="name">
  <h1 class="c-form-part__title u-mb16@xs" pdFocus="true">What's your name?</h1>

  <p>So we can match you to your medical records please enter the name held at your GP surgery.</p>

  <form novalidate #f="ngForm" (ngSubmit)="f.valid && submit()" class="c-form-part__form">
    <div
      class="c-form-part__field-wrap"
      [class.c-form-part__entry--is-invalid]="f.submitted && fname.invalid">
      <label class="c-form-part__field-label" for="ipt-first-name">First name</label>
      <input
        [(ngModel)]="FirstName"
        #fname="ngModel"
        name="FirstName"
        (change)="FirstName = FirstName.trim()"
        class="c-form-part__field"
        id="ipt-first-name"
        type="text"
        pdrValidName
        required
        aria-required="true"
        autocomplete="given-name" />
      <p
        class="c-form-part__error-message"
        *ngIf="fname?.errors?.required || fname?.errors?.invalidName">
        A valid first name is required
      </p>
    </div>

    <div
      class="c-form-part__field-wrap"
      [class.c-form-part__entry--is-invalid]="f.submitted && sname.invalid">
      <label class="c-form-part__field-label" for="ipt-last-name">Last name</label>
      <input
        [(ngModel)]="LastName"
        name="LastName"
        #sname="ngModel"
        (change)="LastName = LastName.trim()"
        class="c-form-part__field"
        id="ipt-last-name"
        type="text"
        pdrValidName
        required
        aria-required="true"
        autocomplete="family-name" />
      <p
        class="c-form-part__error-message"
        *ngIf="sname?.errors?.required || sname?.errors?.invalidName">
        A valid last name is required
      </p>
    </div>

    <div
      class="c-form-part__field-wrap"
      [class.c-form-part__entry--is-invalid]="f.submitted && prevsname.invalid">
      <label class="c-form-part__field-label" for="ipt-prev-last-name">Previous last name</label>
      <input
        [(ngModel)]="Previous"
        name="Previous"
        #prevsname="ngModel"
        (change)="Previous = Previous.trim()"
        class="c-form-part__field"
        id="ipt-prev-last-name"
        type="text"
        pdrValidName />
      <p class="c-form-part__error-message" *ngIf="prevsname?.errors?.invalidName">
        Please enter a valid name.
      </p>
    </div>

    <button
      class="c-btn c-btn--primary c-form-part__btn"
      type="Submit"
      trackClick="next"
      aria-label="Click next to continue">
      Next
    </button>
  </form>
</section>
