import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginService } from '../../services/login/login.service';
import { NhsAuthorisationErrorModalComponent } from './nhs-authorisation-error-modal/nhs-authorisation-error-modal.component';
import { AnalyticsBusService, AnalyticsEvent } from '@pushdr/common/data-access/analytics';
import { ModalService } from '@pushdr/common/overlay';

@Component({
  selector: 'pushdr-nhs-authorisation',
  templateUrl: './nhs-authorisation.component.html',
})
export class NhsAuthorisationComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  activatedRouteSub$: Subscription;
  nhsCode: string;
  error = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private simpleModal: SimpleModalService,
    private modal: ModalService,
    private bus: AnalyticsBusService,
    private login: LoginService
  ) {}

  ngOnInit() {
    this.subscribeToRouteParams();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  private subscribeToRouteParams() {
    this.activatedRouteSub$ = this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: params => {
          if (params['code']) {
            this.nhsCode = params['code'];
            this.login.signInWithNhs(this.nhsCode);
          } else {
            const nhsAuthError = {
              errorMessage: params['error_description'] || '',
              errorCode: params['error'] || '',
              errorURI: params['error_uri'] || '',
              errorState: params['state'] || '',
            };

            this.simpleModal.addModal(
              NhsAuthorisationErrorModalComponent,
              { errorMessage: nhsAuthError.errorMessage },
              {
                closeOnClickOutside: true,
                closeOnEscape: true,
              }
            );

            this.bus.trackEvent(
              AnalyticsEvent.error(
                `NHS AUTH ERROR: ${nhsAuthError.errorCode}`,
                `${nhsAuthError.errorMessage} :: ${nhsAuthError.errorURI} :: ${nhsAuthError.errorState}`
              )
            );

            this.router.navigate(['/register', 'login']);
          }
        },
        error: err => {
          this.modal.error(err.message);
          this.router.navigate(['/register', 'signup']);
        },
      });
  }
}
