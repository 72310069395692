import { Component, OnInit } from '@angular/core';
import { CustomerPreviousName } from '@pushdr/common/types';
import { StorageService } from '@pushdr/common/utils';
import { FlowStateService } from '../../services/funnel-flow/flow-state.service';
import { FlowService } from '../../services/funnel-flow/flow.service';

@Component({
  selector: 'pushdr-name',
  templateUrl: './name.component.html',
})
export class NameComponent implements OnInit {
  FirstName: string = this.state.patient.FirstName;
  LastName: string = this.state.patient.LastName;
  Previous = '';
  PreviousName: CustomerPreviousName;

  constructor(
    private flow: FlowService,
    private state: FlowStateService,
    private store: StorageService
  ) {}

  ngOnInit() {
    // wipe the  registration now we're here - clears sen
    this.store.deleteSessionStorage('registration');
    if (
      Array.isArray(this.state.patient.PreviousNames) &&
      this.state.patient.PreviousNames.length
    ) {
      this.Previous = this.state.patient.PreviousNames.map(names => names.LastName || '').shift();
    }
  }

  submit() {
    const PreviousNames = this.Previous ? [this.previousNameFactory(this.Previous.trim())] : [];
    this.state.updatePatientModel({
      FirstName: this.FirstName.trim(),
      LastName: this.LastName.trim(),
      PreviousNames,
    });

    this.flow.next();
  }

  private previousNameFactory(
    LastName,
    FirstName = this.FirstName,
    ChangedDate = null
  ): CustomerPreviousName {
    return {
      FirstName,
      LastName,
      ChangedDate,
    };
  }
}
