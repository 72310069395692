import { Injectable } from '@angular/core';
import { StorageService } from '@pushdr/common/utils';
import { ClinicianType } from '@pushdr/common/types';

export const MARKETPLACE_PARTNER = 'MARKETPLACE_PARTNER';
export const MARKETPLACE_CLINICIAN = 'MARKETPLACE_CLINICIAN';

@Injectable({
  providedIn: 'root',
})
export class PatientMarketplaceService {
  constructor(private storage: StorageService) {}

  getPartnerId() {
    return this.storage.get(MARKETPLACE_PARTNER);
  }

  getClinicianType() {
    const storedClinicianType = this.storage.get(MARKETPLACE_CLINICIAN);
    return storedClinicianType || ClinicianType.GeneralPractitioner;
  }

  storePartnerId(partnerId: string) {
    if (!partnerId) return;
    this.storage.set(MARKETPLACE_PARTNER, partnerId);
  }

  storeClinicianType(ClinicianType: ClinicianType) {
    if (!ClinicianType) return;
    this.storage.set(MARKETPLACE_CLINICIAN, ClinicianType);
  }

  clear() {
    this.storage.delete(MARKETPLACE_PARTNER);
    this.storage.delete(MARKETPLACE_CLINICIAN);
  }
}
