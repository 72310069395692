import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { EnvironmentProxyService } from '@pushdr/environment';

import { ZENDESK_PUBLIC_KEY } from './models';
import { ZendeskService } from './zendesk.service';

export const zendeskKeyProvider: FactoryProvider = {
  provide: ZENDESK_PUBLIC_KEY,
  useFactory: (env: EnvironmentProxyService) => env.environment.zendesk.key,
  deps: [EnvironmentProxyService],
};

export const zendeskInitializer: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: (zendesk: ZendeskService) => {
    return () => zendesk.bootstrapWidgetSnippet();
  },
  deps: [ZendeskService],
  multi: true,
};
