import { InjectionToken } from '@angular/core';
import { AcceptanceType } from '@pushdr/consent/api';

export interface ConsentHandler {
  // Callbacks for accepted/rejected consents
  accepted(type?: AcceptanceType): void;
  rejected(type?: AcceptanceType): void;
}

// Used to handle the consent acceptance/rejection (i.e. to redirect to booking page)
export const CONSENT_HANDLER = new InjectionToken<ConsentHandler>('Consent Handler');
