import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InputSanitizerService {
  phoneCountryCodeRegex = /\+440|\+44|^00440|^0044/gm;
  whitespaceRegex = /\s/g;

  username(value: string) {
    if (!value) return '';
    const val = this.removeWhiteSpace(value);
    if (val.indexOf('@') === -1) {
      return this.formatPhoneNumber(val);
    } else {
      return val;
    }
  }

  private removeWhiteSpace(val: string) {
    return val.replace(this.whitespaceRegex, '');
  }

  private formatPhoneNumber(val: string) {
    return val.replace(this.phoneCountryCodeRegex, '0');
  }
}
