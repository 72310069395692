import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import {
  PasswordStrengthConditions,
  PasswordStrengthConditionsNotMet,
  PDServerError,
} from '@pushdr/common/types';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { ModalService } from '@pushdr/common/overlay';
import { Observable } from 'rxjs';
import { FlowStateService } from '../../../services/funnel-flow/flow-state.service';
import { FlowService } from '../../../services/funnel-flow/flow.service';

@Component({
  selector: 'pushdr-password',
  templateUrl: './password.component.html',
})
export class PasswordComponent implements OnInit {
  errors: string[];
  rules$: Observable<PasswordStrengthConditions>;
  form: UntypedFormGroup;
  isLoaderVisible = false;

  constructor(
    private flow: FlowService,
    private state: FlowStateService,
    private api: ApiNHSPatientService,
    private modal: ModalService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.rules$ = this.api.validation.passwordConditions();
    this.form = this.formBuilder.group({
      password: [this.state.registration.Password || '', [Validators.required]],
    });
  }

  submit({ value }: { value: { password: string } }) {
    if (this.isLoaderVisible) return;
    this.showLoader();
    this.api.validation.passwordStrength(value.password).subscribe(
      res => {
        //positive no error path
        this.state.updateRegistrationModel({
          Password: value.password,
        });
        this.modalClose();
        this.flow.next();
      },
      (err: PDServerError) => {
        this.modalClose();
        this.errors = (err.original.error as PasswordStrengthConditionsNotMet).conditionsNotMet;
      }
    );
  }

  disableNextButton() {
    return (
      this.form.get('password').hasError('required') ||
      (this.form.get('password').hasError('notValid') && this.form.get('password').dirty)
    );
  }

  private showLoader() {
    this.modal.showLoader();
    this.isLoaderVisible = true;
  }

  private modalClose() {
    this.modal.close();
    this.isLoaderVisible = false;
  }
}
