import { Injectable } from '@angular/core';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { Observable, forkJoin, of } from 'rxjs';
import {
  Acceptance,
  AcceptanceGroupName,
  ExtendedCustomerAcceptance,
  CustomerAcceptance,
} from '@pushdr/common/types';
import { mergeMap, map } from 'rxjs/operators';

export const DEFAULT_GROUPS = [AcceptanceGroupName.NHS, AcceptanceGroupName.MARKETING];
export const DEFAULT_TASK = '';

@Injectable({
  providedIn: 'root',
})
export class CustomerAcceptancesService {
  constructor(private api: ApiNHSPatientService) {}

  //ヽ( ≧ω≦)ﾉ easy get me latest i need to do call
  getUpdatedAcceptances(
    groups: string[] = DEFAULT_GROUPS,
    type: string = DEFAULT_TASK,
    unSavedAgreements: ExtendedCustomerAcceptance[] = []
  ): Observable<ExtendedCustomerAcceptance[]> {
    groups = groups || [];
    return this.getAcceptanceLeftForCurrentUser$(groups, type).pipe(
      map(res => {
        const [custAcceptances, acceptancesToCheck] = res;
        const acceptancesChanged = acceptancesToCheck
          .filter(acceptance => {
            return (
              !custAcceptances.length ||
              custAcceptances.some(custAcceptance => {
                return (
                  custAcceptance.Type === acceptance.Type &&
                  (custAcceptance.Version < acceptance.Version ||
                    (!custAcceptance.Accepted && acceptance.Mandatory))
                );
              })
            );
          })
          .filter(acceptance => {
            return !unSavedAgreements.some(unSavedAgreement => {
              return unSavedAgreement.Type === acceptance.Type;
            });
          });
        return acceptancesChanged
          .sort((a, b) => a.Type - b.Type)
          .map(a => Object.assign({}, a, { Accepted: false }));
      })
    );
  }

  getAcceptances(
    groups: string[] = DEFAULT_GROUPS,
    type: string = DEFAULT_TASK
  ): Observable<ExtendedCustomerAcceptance[]> {
    return this.getAcceptanceLeftForCurrentUser$(groups, type).pipe(
      map(res => this.mapToExtendedAcceptance(res))
    );
  }

  getAcceptancesAsNewUser(
    groups: string[] = DEFAULT_GROUPS,
    type: string = DEFAULT_TASK
  ): Observable<ExtendedCustomerAcceptance[]> {
    return this.getNewUsersAcceptances$(groups, type).pipe(
      map(res => this.mapToExtendedAcceptance(res))
    );
  }

  private mapToExtendedAcceptance(res: [CustomerAcceptance[], Acceptance[]]) {
    const [custAcceptances, currentAcceptances] = res;
    return currentAcceptances.map(acceptance => {
      const customerAcceptancePartial = custAcceptances
        .filter(cAcceptance => cAcceptance.Type === acceptance.Type)
        .shift() || {
        Accepted: false,
      };

      return Object.assign({}, customerAcceptancePartial, acceptance) as ExtendedCustomerAcceptance;
    });
  }

  updatedAcceptances(submittedAcceptances: ExtendedCustomerAcceptance[]) {
    return this.getCustomerAcceptances$().pipe(
      map(customerAgreements => {
        return customerAgreements.filter(agreement => {
          return !submittedAcceptances.some(acceptance => {
            return agreement.Type === acceptance.Version;
          });
        });
      }),
      map(customerAcceptances => [...customerAcceptances, ...submittedAcceptances]),
      mergeMap(newAcceptances => this.api.customer.updateCustomerAcceptances(newAcceptances))
    );
  }

  getAcceptanceHTML(acceptance: Acceptance) {
    return this.api.acceptance.htmlContent(acceptance);
  }

  getCustomerAcceptances$() {
    return this.api.customer.getCustomerAcceptances().pipe(
      map(acceptances => {
        const confirmedOnly: any[] = acceptances.filter(a => a.DateStamp !== '0001-01-01T00:00:00');
        return Object.values(
          confirmedOnly.reduce((hash, ac) => {
            if (!hash[ac.Type] || hash[ac.Type].Version < ac.Version) {
              hash[ac.Type] = ac;
            }
            return hash;
          }, {})
        ) as CustomerAcceptance[];
      })
    );
  }

  private getAcceptanceLeftForCurrentUser$(acceptanceGroups: string[], acceptanceType: string) {
    const custAcc$ = this.getCustomerAcceptances$();
    const accToCheck$ = this.api.acceptance.query(acceptanceGroups.join(','), acceptanceType);
    return forkJoin([custAcc$, accToCheck$]);
  }

  private getNewUsersAcceptances$(acceptanceGroups: string[], acceptanceType: string) {
    const custAcc$ = of([]);
    const accToCheck$ = this.api.acceptance.query(acceptanceGroups.join(','), acceptanceType);
    return forkJoin([custAcc$, accToCheck$]);
  }
}
