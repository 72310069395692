<div class="c-modal__wrap" [cdkTrapFocus]="ready$ | async">
  <h2>There was an error in the authentication process</h2>

  <div class="modal__body">
    <p>Please try again - if the problem persists please contact our customer experience team.</p>

    <p *ngIf="message">{{ message }}</p>
  </div>

  <div class="modal__footer">
    <button (click)="close()" class="c-btn c-btn--secondary">Ok</button>
  </div>
</div>
