import { Injectable } from '@angular/core';
import { AnalyticsService } from '@pushdr/common/data-access/analytics';
import { Account, Customer, NamedEncryptedUser, Postcode } from '@pushdr/common/types';
import { StorageService } from '@pushdr/common/utils';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { Page, Verification } from './flow-types';

export interface State {
  patient?: Customer;
  registration?: Account;
  verification?: Verification;
}

export interface FlowStateEvent {
  delta: State;
  state: State;
}

export enum SelectedSurgeryType {
  PARTNERED = 'partnered_surgery',
  NON_PARTNERED = 'non_partnered_surgery',
  NO_SURGERY = 'NO_SURGERY',
  TBC = 'TBC',
}

@Injectable()
export class FlowStateService {
  private _patient: Customer;
  private _registration: Account;
  private _verification: Verification;

  state$: Subject<FlowStateEvent> = new Subject();
  loggedInEvent$ = new Subject<void>();
  externalId$ = new ReplaySubject(1);

  constructor(
    private storage: StorageService,
    private analytics: AnalyticsService,
    private api: ApiNHSPatientService
  ) {
    this.initialise();
  }

  hasPassedProfile() {
    return this.patient.Gender !== 3 && !!this._patient.Gender;
  }

  signUpStarted() {
    return !!this.registration.Email;
  }

  usedSocialSignIn() {
    return !!this.registration.SocialSignIn;
  }

  isSigningUp() {
    return this.patient && this.patient.Key;
  }

  get registration() {
    return this._registration || new Account();
  }

  get patient() {
    return this._patient || new Customer();
  }

  get verification() {
    return this._verification || new Verification();
  }

  initialise() {
    this._patient = this.instantiate(Customer);
    this._registration = this.instantiate(Account);
    this._verification = this.instantiate(Verification);
  }

  reset() {
    //retain these for restarters
    const postCodesAndSurgery = {
      SurgeryOds: this.registration.SurgeryOds,
    };
    this._registration = new Account();
    this.updateRegistrationModel(postCodesAndSurgery);

    this._patient = new Customer();
    this.updatePatientModel();

    this._verification = new Verification();
    this.updateVerificationModel();
  }

  emitStateEvent(delta: State) {
    this.state$.next({
      state: this.state,
      delta,
    });
  }

  parseLogin(user: NamedEncryptedUser, patient: Customer) {
    this.updateRegistrationModel({
      Email: user.EncryptedUser.Email,
      Mobile: user.EncryptedUser.Mobile,
    });

    this.updatePatientModel({
      ...patient,
      ...{
        FirstName: user.SuggestedFirstName || this.patient.FirstName,
        LastName: user.SuggestedLastName || this.patient.LastName,
        DOB: this.patient.DOB || null,
        Key: user.EncryptedUser.CustomerKey,
      },
    });
    this.loggedInEvent$.next();
  }

  apiRequestForPage<T>(page: Page): Observable<T> {
    if (!page) {
      console.warn(
        '[WARNING]: flow-state exception: An attempt to get an API request for a null page occured'
      );
      return null;
    }
    if (!page.submit) {
      return null;
    }
    switch (true) {
      case page.submit.registration && this.isSignedIn:
        return this.api.account.update(this.registration);
      case page.submit.registration && !this.isSignedIn:
        return this.api.account.create(this.registration).pipe(
          tap(account => {
            this.externalId$.next(account.ExternalId);
            this.fireEventOnceFireBaseIsReady({ action: 'client_registration_started' });
          })
        );
      case page.submit.patient:
        return this.api.customer.updateCustomer(this.patient);
      case page.submit.verify:
        return null; // API needed
      default:
        return null;
    }
  }

  fireEventOnceFireBaseIsReady(event) {
    this.analytics.fireBaseInitialized$.pipe(take(1)).subscribe(() => {
      this.analytics.trackEvent(event);
    });
  }

  updatePatientModel(partialObject: Customer = {}) {
    this._patient = Object.assign(this.patient, partialObject);
    this.storage.setSessionStorage(this.storeKey(this.patient), this.patient);
    this.emitStateEvent({ patient: partialObject });
  }

  updateRegistrationModel(partialObject: Account = {}) {
    this._registration = Object.assign(this.registration, partialObject);
    this.storage.setSessionStorage(this.storeKey(this.registration), this.registration);
    this.emitStateEvent({ registration: partialObject });
  }

  updateVerificationModel(partialObject: Verification = {}) {
    this._verification = Object.assign(this.verification, partialObject);
    this.storage.setSessionStorage(this.storeKey(this.verification), this.verification);
    this.emitStateEvent({ verification: partialObject });
  }

  getModelByName(name) {
    switch (name) {
      case 'registration':
        return this.registration;
      case 'patient':
        return this.patient;
      case 'verification':
        return null;
      default:
        throw new Error('FlowService: Unknown model name - ' + name);
    }
  }

  getSelectedSurgeryStore(): {
    surgeryType: SelectedSurgeryType;
    surgeryName: string;
    odsCode: string;
  } {
    return {
      surgeryType: this.storage.get('SURGERY_TYPE') || SelectedSurgeryType.TBC,
      odsCode: this.storage.get('ODS_CODE'),
      surgeryName: this.storage.get('SURGERY_NAME'),
    };
  }

  setSelectedSurgeryStore(
    surgery?: { odsCode: string; surgeryName?: string },
    isPartnered = false
  ) {
    const surgeryType: SelectedSurgeryType = surgery.odsCode
      ? isPartnered
        ? SelectedSurgeryType.PARTNERED
        : SelectedSurgeryType.NON_PARTNERED
      : SelectedSurgeryType.NO_SURGERY;
    this.storage.set('SURGERY_NAME', surgery.surgeryName, null);
    this.storage.set('SURGERY_TYPE', surgeryType, null);
    this.storage.set('ODS_CODE', surgery.odsCode, null);
  }

  clearSelectedSurgeryStore() {
    this.storage.delete('SURGERY_TYPE');
    this.storage.delete('ODS_CODE');
  }

  private get isSignedIn() {
    return !!this.patient.Key;
  }

  private get state(): State {
    return {
      patient: JSON.parse(JSON.stringify(this.patient)),
      registration: JSON.parse(JSON.stringify(this.registration)),
      verification: JSON.parse(JSON.stringify(this.verification)),
    };
  }

  /**
   * Loads object from storage, or creates a new one, saves it and returns it
   * @param ModelClass Class type you want to create a new one of possibly (name of store internally resolved)
   */
  private instantiate(ModelClass: { new () }) {
    const newModel = new ModelClass();
    const key = this.storeKey(newModel);
    let modelInstance = this.storage.getSessionStorage(key, true);
    if (!modelInstance) {
      modelInstance = newModel;
      this.storage.setSessionStorage(key, modelInstance);
    }
    return Object.assign(Object.create(newModel), modelInstance);
  }

  private storeKey(model: Customer | Account | Verification) {
    switch (true) {
      case model instanceof Customer:
        return 'patient';
      case model instanceof Account:
        return 'registration';
      case model instanceof Verification:
        return 'verification';
      default:
        throw new Error('FlowService: Unknown model type');
    }
  }
}
