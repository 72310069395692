import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { GPPractice, GPSearchResult, ManualGPInput, PDServerError } from '@pushdr/common/types';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { ApiPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { ButtonTypesEnum, ModalService } from '@pushdr/common/overlay';
import { Observable, of } from 'rxjs';
import { catchError, share, tap } from 'rxjs/operators';
import { FindSurgeryHelpModalComponent } from '../../find-surgery-help-modal/find-surgery-help-modal.component';

@Component({
  selector: 'pushdr-edit-surgery',
  templateUrl: './edit-surgery.component.html',
  styleUrls: ['./edit-surgery.component.scss'],
})
export class EditSurgeryComponent implements OnInit {
  @Output() selected = new EventEmitter<GPPractice>();
  @Output() selectedNone = new EventEmitter<boolean>();
  @Output() loaded = new EventEmitter<boolean>();
  @Output() searched = new EventEmitter<void>();

  @Input()
  enableSelectNone = false;
  @Input()
  title = true;
  @Input()
  preload = true;

  postcodeOrSurgeryName: string;
  surgeries: GPPractice[];
  surgeriesObservable$: Observable<GPPractice[]>;
  manualGpForm: ManualGPInput;

  manualGpMatches$: Observable<GPSearchResult[]>;

  fetched = false;
  counter = 1;

  constructor(
    private api: ApiPatientService,
    private nhsApi: ApiNHSPatientService,
    private modal: ModalService
  ) {}

  ngOnInit() {
    this.preload ? this.loadInitialData() : (this.surgeriesObservable$ = of([]));
  }

  submitPostcode(postcode) {
    if (!postcode) return;

    this.surgeriesObservable$ = this.fetchSurgeries(postcode);
    this.surgeriesObservable$.subscribe({
      next: gps => {
        this.surgeries = gps;
        this.loaded.emit();
        this.searched.emit();
      },
    });
  }

  selectSurgery(surgery) {
    this.modal
      .confirm(
        'Confirm your surgery',
        surgery.Name,
        'Confirm',
        'Cancel',
        undefined,
        ButtonTypesEnum.primary,
        this.generateConfirmSurgeryHtml(surgery)
      )
      .subscribe(result => {
        if (result) this.selected.emit(surgery);
      });
  }

  generateConfirmSurgeryHtml(surgery): string {
    let res = `<div class="flex flex-col">`;
    for (let index = 1; index < 6; index++) {
      if (surgery[`AddressLine${index}`]) {
        res = res.concat(
          `<span class="u-font-weight-light">${surgery[`AddressLine${index}`]}</span>`
        );
      }
    }
    if (surgery.PostCode)
      res = res.concat(`<span class="u-font-weight-light">${surgery.PostCode}</span>`);
    res = res.concat('</div>');
    return res;
  }

  selectNoSurgery() {
    return this.modal
      .confirm(
        'What it means if you don’t provide GP surgery details',
        'By not adding a surgery this means that you are opting-in to our paid service. If you wish to add surgery details in the future you can do this in your account area and check your eligibilty for free NHS services.',
        'Register for paid service'
      )
      .subscribe(res => (res ? this.selectedNone.emit(true) : this.modal.close()));
  }

  help() {
    return this.modal.showCustom(FindSurgeryHelpModalComponent, null, null, {
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  }

  setCounter() {
    this.counter++;
  }

  private loadInitialData() {
    this.api.account.getMyAddress().subscribe(
      response => {
        this.postcodeOrSurgeryName = this.postcodeOrSurgeryName || response.PostCode || 'SW1A1AA';
        this.surgeriesObservable$ = this.fetchSurgeries(this.postcodeOrSurgeryName);
        this.surgeriesObservable$.subscribe({
          next: gps => {
            this.surgeries = gps;
            this.searched.emit();
          },
        });
      },
      error => {
        this.handleError(error);
      }
    );
  }

  private fetchSurgeries(postcodeOrSurgeryName: string) {
    return this.nhsApi.gppractices.searchSurgery(postcodeOrSurgeryName.trim()).pipe(
      catchError(e => {
        this.handleError(e);
        return of([]);
      }),
      tap(() => (this.fetched = true)),
      share()
    );
  }

  private handleError(err: PDServerError) {
    this.surgeries = [];
    if (err.status !== 401 && err.status !== 403 && err.status !== 500) {
      this.modal.error(err.message);
    }
  }
}
