import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pushdr-big-green-tick',
  templateUrl: './big-green-tick.component.html',
  styleUrls: ['./big-green-tick.component.scss'],
})
export class BigGreenTickComponent {
  constructor() {}
}
