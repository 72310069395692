import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pushdr-consent-info',
  templateUrl: './consent-info.component.html',
  styleUrls: ['./consent-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentInfoComponent implements OnChanges {
  _termsContent$: Observable<SafeHtml>;

  @Input() termsUrl = '';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('termsUrl' in changes) {
      this._termsContent$ = this.getTermsContent();
    }
  }

  private getTermsContent(): Observable<SafeHtml> {
    return from(fetch(this.termsUrl)).pipe(
      switchMap(response => from(response.text())),
      map(content => content.match(/<body.*?>(.*)<\/body>/ms)),
      map(content => this.sanitizer.bypassSecurityTrustHtml(content[1]))
    );
  }
}
