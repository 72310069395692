import { Component, Inject, OnInit } from '@angular/core';
import { ExtendedWindow, WINDOW } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { ApiPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { catchError, debounceTime, map, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AnalyticsService } from '@pushdr/common/data-access/analytics';
import { ActionRequestService } from '@pushdr/patientapp/common/services';
import { Router } from '@angular/router';
import { AccountProfileService } from '@pushdr/patientapp/common/utils';

@Component({
  selector: 'pushdr-verification-success',
  templateUrl: './verificationsuccess.component.html',
})
export class VerificationSuccessComponent implements OnInit {
  checkedBooking$: Observable<boolean>;

  constructor(
    private oldApi: ApiPatientService,
    private envProxy: EnvironmentProxyService,
    private analytics: AnalyticsService,
    private actionRequest: ActionRequestService,
    private router: Router,
    private profile: AccountProfileService,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {}

  ngOnInit() {
    this.analytics.trackEvent({ action: 'client_registration_complete' });
    this.checkedBooking$ = this.actionRequest.claim$().pipe(
      catchError(() => of(null)),
      switchMap(() => this.profile.resolve()),
      switchMap(() => this.checkIfHasBooking$()),
      debounceTime(500),
      map(hasBooking => {
        if (hasBooking) {
          this.window.location.href = this.envProxy.environment.patient.account.appointments;
          return false;
        }
        this.router.navigate(['/booking/nhs']);
        return true;
      })
    );
  }

  private checkIfHasBooking$(): Observable<boolean> {
    return this.oldApi.booking.getAppointmentSummary().pipe(
      take(1),
      map(appointments => {
        return (
          appointments.filter(appt => {
            const status = appt.strAppointmentStatus.toLocaleLowerCase();
            return status === 'in progress' || status === 'scheduled';
          }).length > 0
        );
      })
    );
  }
}
