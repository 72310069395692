import { Component, OnInit } from '@angular/core';
import { InviteType } from '@pushdr/common/types';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { ModalService } from '@pushdr/common/overlay';
import { InviteService } from '@pushdr/patientapp/common/services';
import { mergeMap } from 'rxjs/operators';
import { FlowStateService } from '../../services/funnel-flow/flow-state.service';
import { FlowService } from '../../services/funnel-flow/flow.service';
import { DatesService } from '@pushdr/common/utils';

@Component({
  selector: 'pushdr-dob',
  templateUrl: './dob.component.html',
})
export class DobComponent implements OnInit {
  constructor(
    private flow: FlowService,
    private state: FlowStateService,
    private dates: DatesService,
    private api: ApiNHSPatientService,
    private modal: ModalService,
    private invite: InviteService
  ) {}

  error = '';
  dobComposite = { dd: '', mm: '', yyyy: '' };
  isLoaderVisible = false;

  /** TODO
   *
   * Validate the date of birth in here when they press next
   * and handle fail if this in on the invite path
   *
   */

  ngOnInit() {
    this.dobComposite = this.dates.populateDate(this.state.patient.DOB);
  }

  dd_mm_yyyy(d = '-') {
    return `${this.dobComposite.dd}${d}${this.dobComposite.mm}${d}${this.dobComposite.yyyy}`;
  }

  submit() {
    if (this.isLoaderVisible) return;
    this.showLoader();
    this.error = '';
    this.invite
      .getInvite(InviteType.BOOKING)
      .pipe(mergeMap(invite => this.api.validation.dob(this.dd_mm_yyyy(), invite)))
      .subscribe(
        () => {
          this.modalClose();
          this.state.updatePatientModel({
            DOB: this.dates.getUTCDate(
              this.dobComposite.yyyy,
              this.dobComposite.mm,
              this.dobComposite.dd
            ),
          });
          this.flow.next();
        },
        error => {
          this.modalClose();
          this.error = this.api.errorMessages.validation.dob(error).message;
        }
      );
  }

  private showLoader() {
    this.modal.showLoader();
    this.isLoaderVisible = true;
  }

  private modalClose() {
    this.modal.close();
    this.isLoaderVisible = false;
  }
}
