<div class="c-modal__wrap c-modal__wrap--40" [cdkTrapFocus]="ready$ | async">
  <div class="c-modal__head">
    <div class="c-modal__title">
      <h4>{{ data.title }}</h4>
    </div>
    <div class="flex flex-col gap-1">
      <button class="btn-outline px-4 md:px-12" (click)="output()">
        {{ cancelText }}
      </button>
      <button *ngIf="callToAction" class="btn-primary px-4 md:px-12" (click)="done(true)">
        {{ callToAction }}
      </button>
    </div>
  </div>
  <iframe
    class="w-full border border-bluegrey-300"
    *ngIf="ready$ | async"
    #iframeInst
    title="{{ data.title }}"
    [src]="safeUrl"></iframe>
</div>

<ng-template #error>
  <h3>
    Something went wrong. If you wish to read this bulletin, please contact our operations team.
  </h3>
  <button class="btn-outline" (click)="output()">Close</button>
</ng-template>
