import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private defaultTitle = '';

  constructor(private meta: Meta, private title: Title) {
    this.defaultTitle = this.title.getTitle();
  }

  getTitle() {
    return this.title.getTitle();
  }

  setTitle(title: string) {
    this.title.setTitle(title);
  }

  resetTitle() {
    this.title.setTitle(this.defaultTitle);
  }

  unblockRobots() {
    this.meta.updateTag({ name: 'robots', content: 'INDEX, FOLLOW' });
  }

  blockRobots() {
    this.meta.updateTag({ name: 'robots', content: 'NOINDEX, NOFOLLOW' });
  }

  isOpenToRobots() {
    const metaEl: HTMLMetaElement = this.meta.getTag('name = "robots"');
    return metaEl.content === 'INDEX, FOLLOW';
  }
}
