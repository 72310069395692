import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { catchError, publishReplay, refCount, switchMap, tap } from 'rxjs/operators';

import { ModalService } from '@pushdr/common/overlay';
import { Acceptance, AcceptanceType } from '@pushdr/consent/api';

import { AcceptanceService } from '../acceptance.service';
import { ConsentService } from '../consent.service';
import { ConsentHandler, CONSENT_HANDLER } from '../models';

@Component({
  selector: 'pushdr-consent-review',
  templateUrl: './consent-review.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentReviewComponent {
  AcceptanceType = AcceptanceType;

  acceptance$ = this.acceptance.getAcceptance().pipe(
    catchError(err => this.showErrorModal(err, 'Failed to obtain acceptance.')),
    // Cache for optimizing requests
    publishReplay(1),
    refCount()
  );

  constructor(
    private modal: ModalService,
    private acceptance: AcceptanceService,
    private consent: ConsentService,
    @Inject(CONSENT_HANDLER) private consentHandler: ConsentHandler
  ) {}

  onAccept({ Type: acceptanceType }: Acceptance): void {
    this.acceptConsent(acceptanceType).subscribe({
      next: () => this.consentHandler.accepted(acceptanceType),
      error: err => this.showErrorModal(err.message, 'Failed to update consent'),
    });
  }

  onDecline({ Type: acceptanceType }: Acceptance): void {
    this.consentHandler.rejected(acceptanceType);
  }

  private acceptConsent(type: AcceptanceType) {
    return this.consent.getConsent(type).pipe(
      // Accept consent for given acceptance type
      switchMap(consent => this.consent.accept(consent))
    );
  }

  private showErrorModal(err: Error, title: string) {
    return this.modal.error(err.message, title).pipe(
      // Reload page on encountering an error
      tap(() => window.location.reload())
    );
  }
}
