import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { map, switchMap, throwIfEmpty } from 'rxjs/operators';

import {
  Acceptance,
  AcceptanceApiService,
  AcceptanceGroup,
  AcceptanceType,
} from '@pushdr/consent/api';

import { CustomerApiService } from '@pushdr/patientapp/common/data-access/patient-api';

@Injectable({
  providedIn: 'root',
})
export class AcceptanceService {
  constructor(private customer: CustomerApiService, private acceptance: AcceptanceApiService) {}

  getAcceptanceType(): Observable<AcceptanceType> {
    return this.customer.getPartner().pipe(
      map(partner => partner?.ConsultationRecording),
      map(recordingEnabled => selectAcceptanceType(recordingEnabled))
    );
  }

  getAcceptance(): Observable<Acceptance> {
    return this.getAcceptanceType().pipe(
      switchMap(type => this.getNhsAcceptance(type)),
      switchMap(acceptances => (acceptances?.[0] ? of(acceptances[0]) : EMPTY)),
      throwIfEmpty(() => new Error('Acceptance is not available.'))
    );
  }

  private getNhsAcceptance(type: AcceptanceType) {
    const nhsGroups = [AcceptanceGroup.NHS];
    return this.acceptance.getAcceptances(nhsGroups, type);
  }
}

function selectAcceptanceType(recordingEnabled: boolean): AcceptanceType {
  return recordingEnabled ? AcceptanceType.GOVERNANCE : AcceptanceType.MEDICAL_RECORDS_CONSENT;
}
