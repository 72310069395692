<ng-container *ngIf="partnerName$ | async as partnerName; else loading">
  <section class="max-w-sm m-auto text-bluegrey-800">
    <div class="pb-6 mb-6 border-b border-grey-200">
      <h3 class="mb-4 font-normal">
        <span>Book an appointment</span>
      </h3>
      <div class="flex justify-between">
        <div>
          <h5 class="mt-0 mb-1">NHS account</h5>
          <p id="partnerName" class="mb-0">
            {{ partnerName }}
          </p>
        </div>
        <div>
          <span class="px-4 py-2 text-sm text-indigo-700 bg-teal-500 rounded-full">Default</span>
        </div>
      </div>
    </div>
    <div class="mb-10">
      <h5 class="mt-0 mb-6">Account abilities</h5>
      <ul class="pl-4 green-checked-list">
        <li class="mb-2">GP video appointments</li>
        <li class="mb-2">NHS prescriptions</li>
        <li class="mb-2">Fit (sick) notes and NHS referrals</li>
      </ul>
    </div>

    <button
      class="w-full py-4 btn btn-primary"
      [routerLink]="['/booking/emergency']"
      trackClick="next">
      <span>Book appointment</span>
    </button>
  </section>
</ng-container>

<!-- Loader spinner for the page -->
<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>
