<!--  Loader spinner for the page -->
<ng-container *ngIf="redirecting; else signInPage">
  <pushdr-spinner-loader
    title="NHS login"
    message="You are now being redirected to the NHS login website.">
  </pushdr-spinner-loader>
</ng-container>

<ng-template #signInPage>
  <section class="c-form-part" trackView="sign in">
    <h1 *ngIf="BothLogin" class="c-form-part__title" pdFocus="true">
      Log in to Push Doctor
      <span class="u-sr-only">contains a form with username and password inputs</span>
    </h1>
    <h1 *ngIf="MobileLogin" class="c-form-part__title" pdFocus="true">
      An account already exists with that mobile number. Please log in.
    </h1>
    <h1 *ngIf="EmailLogin" class="c-form-part__title" pdFocus="true">
      An account already exists with that email address. Please log in.
    </h1>
    <form novalidate #f="ngForm" (ngSubmit)="f.valid && submit()" class="c-form-part__form">
      <input
        *ngIf="EmailLogin"
        type="hidden"
        [(ngModel)]="Email"
        #email="ngModel"
        name="email"
        class="c-form-part__field"
        id="ipt-email-hidden"
        aria-required="true"
        autocomplete="email" />

      <input
        *ngIf="MobileLogin"
        type="hidden"
        [(ngModel)]="Mobile"
        #email="ngModel"
        name="mobile"
        class="c-form-part__field"
        id="ipt-mobile-hidden"
        aria-required="true"
        autocomplete="tel" />

      <div
        *ngIf="BothLogin"
        class="c-form-part__field-wrap"
        [class.c-form-part__entry--is-invalid]="emailMobile.invalid && f.submitted">
        <label for="ipt-email-or-mobile">Your email address or mobile number</label>
        <input
          [(ngModel)]="Email"
          #emailMobile="ngModel"
          name="emailMobile"
          class="c-form-part__field"
          id="ipt-email-or-mobile"
          required
          aria-required="true"
          type="text"
          required />
        <p
          class="c-form-part__error-message"
          id="err-msg-email-required"
          *ngIf="emailMobile?.errors?.required">
          An email address or a UK mobile number is required to log in.
        </p>
      </div>

      <div
        class="c-form-part__field-wrap u-mb16@xs"
        [class.c-form-part__entry--is-invalid]="pw.invalid && f.submitted">
        <label class="c-form-part__field-label" for="ipt-password">Password</label>
        <input
          [(ngModel)]="Password"
          name="Password"
          #pw="ngModel"
          required
          class="c-form-part__field"
          id="ipt-password"
          aria-required="true"
          type="password" />
        <p
          class="c-form-part__error-message"
          id="err-msg-password-required"
          *ngIf="pw?.errors?.required">
          A password is required to log in.
        </p>
      </div>
      <p tabindex="0" class="c-form-part__field-tip u-mb40@xs">
        Forgot your password?
        <a [routerLink]="['/register/getcode']" trackClick="reset password">Reset it</a>
      </p>

      <button class="c-btn c-btn--primary c-form-part__btn" value="Log in" trackClick="sign in">
        Log in with your email
      </button>
    </form>

    <div class="separator">OR</div>

    <div class="c-form-part__margin-wrap c-form-part__margin-wrap u-pt24@xs">
      <img
        title="Log in with NHS login"
        alt="sign-in-with-nhs"
        src="/assets/nhs-assets/nhs_sign_in_original.svg"
        class="btn-nhs u-mb24@xs"
        tabindex="0"
        (click)="signInWithNHS()" />
    </div>
    <p class="u-text-center">
      <strong>Need an account? </strong
      ><a [routerLink]="['/surgery-preselect']" trackClick="sign up">Register here</a>
    </p>
  </section>
</ng-template>
