<div trackView="surgery select" class="o-row o-row--margin-bottom-small u-mt24@xs">
  <div class="col-xs-12 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
    <div class="c-form-part">
      <pushdr-edit-surgery
        (selected)="selectSurgery($event)"
        (selectedNone)="selectNone()"
        (loaded)="readyToSelect()"
        [enableSelectNone]="enableSelectNone">
      </pushdr-edit-surgery>
    </div>
  </div>
</div>

<!--  Loader spinner for the page -->
<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>
