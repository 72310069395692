import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, Params } from '@angular/router';
import { private_registration } from '@pushdr/common/utils';
import { FlowStateService, SelectedSurgeryType } from '../services/funnel-flow/flow-state.service';

@Injectable()
export class SurgerySelectedGuard implements CanActivate {
  constructor(private router: Router, private flowStateService: FlowStateService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const predicates = [this.isPartnered(), this.hasAnInvite(route.queryParams)];
    if (this.allowPrivatePatients()) {
      predicates.push(this.surgerySelected());
    }

    if (predicates.find(reasonToContinue => reasonToContinue === true)) {
      return true;
    } else {
      this.router.navigate(['/surgery-preselect'], {
        queryParams: route.queryParams,
        replaceUrl: true,
      });
      return false;
    }
  }

  private allowPrivatePatients() {
    // Replaces optimizely 'private_registration' flag
    // TODO: Add proper config for 'private_registration' flag
    const privateRegistrationConfig = private_registration;
    return privateRegistrationConfig.enabled;
  }

  private isPartnered() {
    return (
      this.flowStateService.getSelectedSurgeryStore().surgeryType === SelectedSurgeryType.PARTNERED
    );
  }

  private surgerySelected() {
    return this.flowStateService.getSelectedSurgeryStore().surgeryType !== SelectedSurgeryType.TBC;
  }

  private hasAnInvite(params: Params) {
    return !!(params && params.invitationId && params.partnerId && params.promotionId);
  }
}
