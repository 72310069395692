<section
  class="c-form-part"
  *ngIf="checkedBooking$ | async; else loading"
  trackView="account complete">
  <h1 class="c-form-part__title" pdFocus="true">Account setup complete</h1>
  <p tabindex="0">
    Great! Your account has been fully set up, you can now book an online appointment.
  </p>
  <a
    [routerLink]="['/booking', 'consent']"
    class="c-btn c-btn--primary c-form-part__btn"
    trackClick="next">
    Book appointment
  </a>
</section>

<!--  Loader spinner for the page -->
<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>
