import { GPShareStatus, GPPartnershipType } from '@pushdr/common/types';

/**
 * Please do not used this any further in production after new private is back
 * @deprecated
 */

export const userHasValidSurgery = (
  Id: number,
  ShareStatus: GPShareStatus,
  partnerType: GPPartnershipType,
  isNHS: boolean
) => {
  // user in a bad state - is not a valid surgery
  if (isNHS && (!partnerType || !Id)) {
    return false;
  }
  return (
    Id > 0 || ShareStatus === GPShareStatus.VERIFYING || ShareStatus === GPShareStatus.NO_NHS_GP
  );
};
