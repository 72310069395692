import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AcceptanceApiService } from './acceptance-api.service';
import { ConsentApiService } from './consent-api.service';

@NgModule({
  imports: [CommonModule],
  providers: [AcceptanceApiService, ConsentApiService],
})
export class ConsentApiModule {}
