import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { map, switchMap, tap, throwIfEmpty } from 'rxjs/operators';

import { AcceptanceType, ConsentApiService, CustomerAcceptance } from '@pushdr/consent/api';

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  consentAcknowledged = false;

  constructor(private consent: ConsentApiService) {}

  getConsent(type: AcceptanceType): Observable<CustomerAcceptance> {
    return this.getConsents([type]).pipe(
      switchMap(consents => (consents?.[0] ? of(consents[0]) : EMPTY)),
      throwIfEmpty(() => new Error('Consent is not available.'))
    );
  }

  getConsents(types: AcceptanceType[]): Observable<CustomerAcceptance[]> {
    return this.consent.getConsents().pipe(
      // Lookup for all consents with given acceptance type
      map(consents => consents.filter(x => types.includes(x.Type)))
    );
  }

  accept(consent: CustomerAcceptance): Observable<void> {
    const customerAcceptance = { ...consent, Accepted: true };
    return this.consent.updateConsents([customerAcceptance]).pipe(
      // Acknowledge the consent for the current session
      // Used to allow revisiting the consent for governance
      tap(() => (this.consentAcknowledged = true))
    );
  }
}
