import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ConsentApprovalGuard } from '@pushdr/consent/shell';
import { BookingShellComponent } from './booking-shell.component';
import { consentHandlerProvider } from './consent-handler';
import { EmergencyComponent } from './emergency/emergency.component';
import { HomeNhsWelcomeComponent } from './home-nhs-welcome/home-nhs-welcome.component';
import { HomeNhsComponent } from './home-nhs/home-nhs.component';
import { HomePrivateComponent } from './home-private/home-private.component';
import { IsBookingEnabledGuard } from './is-booking-enabled.guard';

const routes: Routes = [
  {
    path: 'booking/nhs',
    component: HomeNhsComponent,
  },
  {
    path: 'booking/nhs-welcome',
    component: HomeNhsWelcomeComponent,
  },
  {
    path: 'booking/private',
    component: HomePrivateComponent,
  },
  {
    path: 'booking/consent',
    loadChildren: () => import('@pushdr/consent/shell').then(m => m.ConsentShellModule),
  },
  {
    path: 'booking',
    canActivate: [ConsentApprovalGuard, IsBookingEnabledGuard],
    data: { redirectToOnFail: ['/booking', 'consent'] },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'emergency',
      },
      {
        path: 'emergency',
        component: EmergencyComponent,
      },
      {
        path: '',
        loadChildren: () =>
          import('@pushdr/patientapp/booking/feature-shell').then(m => m.BookingFeatureShellModule),
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: [BookingShellComponent, HomePrivateComponent, HomeNhsComponent, EmergencyComponent],
  providers: [ConsentApprovalGuard, consentHandlerProvider],
})
export class BookingModule {}
