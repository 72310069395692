import { Injectable, Inject } from '@angular/core';
import { WINDOW, ExtendedWindow } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { KeyValue } from '@angular/common';

const nhsConfigScopeString = [
  { scope: 'address', include: false },
  { scope: 'client_metadata', include: false },
  { scope: 'email', include: true },
  { scope: 'gp_integration_credentials', include: false },
  { scope: 'gp_registration_details', include: true },
  { scope: 'nhs_app_credentials', include: false },
  { scope: 'openid', include: true },
  { scope: 'phone', include: true },
  { scope: 'profile', include: true },
  { scope: 'profile_extended', include: true },
]
  .filter(scope => scope.include)
  .map(s => s.scope)
  .join(' ');

interface NhsSignInOIDCObject {
  nhsRootDomain: string;
  clientId: string;
  scope: string;
  responseType: string;
  redirectUri: string;
  loggingIn: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NhsSignInOIDCService {
  nhsSignInConfig: NhsSignInOIDCObject = {
    nhsRootDomain: this.env.environment.nhsAuth.nhsRootDomain,
    clientId: this.env.environment.nhsAuth.clientId,
    scope: nhsConfigScopeString,
    responseType: 'code',
    redirectUri: this.env.environment.nhsAuth.redirectUri,
    loggingIn: false,
  };

  constructor(
    @Inject(WINDOW) private window: ExtendedWindow,
    private env: EnvironmentProxyService
  ) {}

  // URL-encode params and create a uri
  private uriBuilder(url: string, queries: KeyValue<string, string>[]): string {
    let newUrl = url;
    queries = queries || [];

    for (let i = 0; i < queries.length; i++) {
      newUrl = newUrl + (i === 0 ? '?' : '&');
      newUrl =
        newUrl + encodeURIComponent(queries[i].key) + '=' + encodeURIComponent(queries[i].value);
    }

    return newUrl;
  }

  // Create the href and redirect the window to NHS login
  getAuthCode(): void {
    this.nhsSignInConfig.loggingIn = true;

    const redirectLink = this.uriBuilder(this.nhsSignInConfig.nhsRootDomain + '/authorize', [
      { key: 'client_id', value: this.nhsSignInConfig.clientId },
      { key: 'scope', value: this.nhsSignInConfig.scope },
      { key: 'response_type', value: this.nhsSignInConfig.responseType },
      { key: 'redirect_uri', value: this.nhsSignInConfig.redirectUri },
    ]);

    this.nhsSignInConfig.loggingIn = false;

    this.window.location.href = redirectLink;
  }
}
