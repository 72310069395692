<section class="c-form-part">
  <div
    *ngIf="possibleAgreements"
    aria-hidden="true"
    [trackView]="possibleAgreements?.length ? 'terms' : ''"></div>
  <form
    novalidate
    *ngIf="possibleAgreements?.length"
    #f="ngForm"
    (ngSubmit)="f.valid && submit()"
    class="c-form-part__form">
    <h1 *ngIf="!checkCustomer" class="c-form-part__title" pdFocus="true">Terms and conditions</h1>
    <h1 *ngIf="checkCustomer" class="c-form-part__title" pdFocus="true">
      There have been changes to the following since you visited:
    </h1>
    <p tabindex="0">To continue you need to review these changes</p>
    <div
      *ngFor="let pa of possibleAgreements"
      class="c-form-part__field-wrap"
      [class.c-form-part__entry--is-invalid]="f.submitted && f.invalid">
      <p *ngIf="pa.Content" class="u-mb8@xs">
        <button
          class="c-btn-anchor"
          type="button"
          (click)="readPolicy(pa, checkbox)"
          [trackClick]="'read policy' + pa.Type">
          Read the {{ pa.Name }}
        </button>
      </p>
      <pushdr-input-checkbox
        #checkbox
        class="mt-4 p-4 border rounded"
        [name]="'policy-type-' + pa.Type"
        [class.bg-grey-100]="!pa.Accepted"
        [class.border-grey-100]="!pa.Accepted"
        [class.border-indigo-500]="pa.Accepted"
        [(ngModel)]="pa.Accepted">
        {{ pa.CallToAction }}
      </pushdr-input-checkbox>
      <p
        class="c-form-part__error-message"
        [ngClass]="'error-policy-type-' + pa.Type"
        *ngIf="cb?.errors?.required"
        role="alert">
        This agreement is mandatory.
      </p>
    </div>

    <button type="submit" class="c-btn c-btn--primary c-form-part__btn" value="next">
      Continue
    </button>
  </form>
</section>
