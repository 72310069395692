import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { ExtendedWindow, TokenService, WINDOW } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { IsAuthenticatedService } from '@pushdr/patientapp/common/utils';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ModalService } from '@pushdr/common/overlay';

@Injectable()
export class IsNotFullyAuthedGuard implements CanActivate {
  constructor(
    private auth: IsAuthenticatedService,
    private envProxy: EnvironmentProxyService,
    private modal: ModalService,
    private tokenService: TokenService,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (route.queryParams && route.queryParams.invitationId) {
      this.tokenService.delete();
      return of(true);
    }
    this.modal.showLoader({ bottomText: 'Verifying your authentication' });
    return this.auth.isAuthenticated$().pipe(
      tap(isAuthenticated => {
        if (isAuthenticated) {
          this.modal.showLoader({ bottomText: 'Redirecting to your account' });
          this.window.location.href = this.envProxy.environment.patient.account.url;
        } else {
          this.modal.close();
        }
      }),
      map(isAuthenticated => !isAuthenticated)
    );
  }
}
