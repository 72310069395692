import { Component } from '@angular/core';
import { Invite, InviteType, PDServerError } from '@pushdr/common/types';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { ModalService } from '@pushdr/common/overlay';
import { InviteService } from '@pushdr/patientapp/common/services';
import { EMPTY as empty } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { FlowStateService } from '../../services/funnel-flow/flow-state.service';
import { FlowService } from '../../services/funnel-flow/flow.service';
import { InputSanitizerService } from '@pushdr/patientapp/common/utils';

@Component({
  selector: 'pushdr-mobile',
  templateUrl: './mobile.component.html',
})
export class MobileComponent {
  Mobile: string = this.state.registration.Mobile;
  isLoaderVisible = false;

  constructor(
    private flow: FlowService,
    private state: FlowStateService,
    private api: ApiNHSPatientService,
    private modal: ModalService,
    private sanitizer: InputSanitizerService,
    private invite: InviteService
  ) {}

  submit() {
    if (!this.hasChangedMobileInput()) {
      this.flow.next();
      return;
    }
    if (this.isLoaderVisible) return;
    this.showLoader();
    this.Mobile = this.sanitizer.username(this.Mobile);

    this.invite
      .getInvite(InviteType.BOOKING)
      .pipe(
        catchError(err => {
          this.showInviteExpiredModal(err);
          return empty;
        }),
        mergeMap((invite: Invite) => this.api.validation.canRegisterMobile(this.Mobile, invite))
      )
      .subscribe({
        next: () => {
          this.closeModal();
          this.state.updateRegistrationModel({
            Mobile: this.Mobile,
          });
          this.flow.next();
        },
        error: (error: PDServerError) => {
          this.isLoaderVisible = false;

          if (!this.state.isSigningUp() && error.status === 409) {
            this.gotoToSignIn();
          } else {
            const { message } = this.api.errorMessages.validation.canRegisterMobile(error);
            this.modal.error(message);
          }
        },
      });
  }

  hasChangedMobileInput() {
    return this.state.registration.Mobile !== this.Mobile;
  }

  gotoToSignIn() {
    this.state.updateRegistrationModel({
      Mobile: this.Mobile,
      Email: '',
    });
    this.closeModal();
    this.flow.gotoPage('Log In', { with: 'mobile' });
  }

  // duplication for now
  private showInviteExpiredModal(err) {
    const errMessage = this.api.errorMessages.invitations.commonInviteErrors(err);
    // TODO: btn text should be 'Continue'
    this.modal
      .acknowledge(errMessage.header, errMessage.message)
      .pipe(take(1))
      .subscribe(d => {
        this.invite.flushInviteCookie();
      });
  }

  private showLoader() {
    this.modal.showLoader();
    this.isLoaderVisible = true;
  }

  private closeModal() {
    this.modal.close();
    this.isLoaderVisible = false;
  }
}
