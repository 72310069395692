<div class="c-modal c-modal--is-visible">
  <div class="c-modal__wrap c-modal__wrap--40">
    <div class="modal__body">
      <p class="mb-0">You must agree with these terms to use this service.</p>
    </div>

    <div class="modal__footer">
      <button (click)="done(true)" class="c-btn c-btn--primary">Okay</button>
    </div>
  </div>
</div>
