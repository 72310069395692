import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@pushdr/common/overlay';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { ExtendedCustomerAcceptance, PDServerError } from '@pushdr/common/types';
import { map, take } from 'rxjs/operators';
import { FlowStateService } from '../../services/funnel-flow/flow-state.service';
import { FlowService } from '../../services/funnel-flow/flow.service';
import { LoginService } from '../../services/login/login.service';
import {
  CustomerAcceptancesService,
  DEFAULT_GROUPS,
  DEFAULT_TASK,
} from '@pushdr/patientapp/common/utils';
import { safeExternalRedirect, isExternalRedirect } from '@pushdr/common/utils';
import { IframeModalComponent, InputCheckboxComponent } from '@pushdr/common/components';
import { AcceptanceType } from '@pushdr/consent/api';

@Component({
  selector: 'pushdr-register-terms',
  templateUrl: './terms.component.html',
})
export class TermsComponent implements OnInit {
  constructor(
    private flow: FlowService,
    private state: FlowStateService,
    private api: ApiNHSPatientService,
    private login: LoginService,
    private modal: ModalService,
    private acceptances: CustomerAcceptancesService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  checkCustomer = false;
  redirect = '';
  possibleAgreements: ExtendedCustomerAcceptance[];

  ngOnInit() {
    this.modal.showLoader({
      bottomText: 'Checking terms',
    });
    //to manage alternative copy of when the page is used as check before you continue
    this.route.queryParams.subscribe(q => {
      this.checkCustomer = !!q['checking'];
      this.redirect = q['redirect'] || '';

      this.initTerms();
    });
  }

  initTerms() {
    const unsavedAcceptances = !this.checkCustomer ? this.state.registration.Acceptances : [];
    this.acceptances
      .getUpdatedAcceptances(DEFAULT_GROUPS, DEFAULT_TASK, unsavedAcceptances)
      .pipe(
        take(1),
        map(list => {
          const omitAcceptances = [
            AcceptanceType.MEDICAL_RECORDS_CONSENT,
            AcceptanceType.GOVERNANCE,
          ];
          return list.filter(item => !omitAcceptances.includes(item.Type));
        })
      )
      .subscribe(remainingAcceptances => {
        if (!remainingAcceptances.length) {
          this.continue();
        } else {
          this.modal.close();
          this.possibleAgreements = remainingAcceptances;
        }
      });
  }

  submit() {
    const Acceptances = [...this.state.registration.Acceptances, ...this.possibleAgreements];

    if (!this.checkCustomer) {
      this.state.updateRegistrationModel({ Acceptances });
      this.continue();
    } else {
      this.modal.showLoader();
      this.api.customer.updateCustomerAcceptances(Acceptances).subscribe(
        () => {
          this.continue();
        },
        (error: PDServerError) =>
          this.modal.error(
            this.api.errorMessages.customer.postCustomerAcceptancesErrors(error).message
          )
      );
    }
  }

  continue() {
    this.modal.close();
    if (safeExternalRedirect(this.redirect)) {
      location.href = this.redirect;
    } else if (this.redirect && !isExternalRedirect(this.redirect)) {
      this.router.navigate([this.redirect]);
    } else {
      this.flow.next(
        {},
        {
          callBack: registeredUser => {
            this.login.processStateFromUser({ EncryptedUser: registeredUser }, false);
          },
          streamToSwitch: this.state.loggedInEvent$,
        }
      );
    }
  }

  readPolicy(acceptance: ExtendedCustomerAcceptance, checkbox: InputCheckboxComponent) {
    this.modal
      .showCustom(IframeModalComponent, {
        title: acceptance.Name,
        linkToDocument: acceptance.Content,
        callToAction: 'I agree',
        cancelText: 'I do not agree',
      })
      .subscribe(accepted => {
        if (accepted && !acceptance.Accepted) return checkbox.toggle();
        if (acceptance.Accepted) return checkbox.toggle();
      });
  }
}
