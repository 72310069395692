import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SpinnerLoaderModule } from '@pushdr/common/components';
import { AnalyticsService, LOG_CONFIG } from '@pushdr/common/data-access/analytics';
import { CommonOverlayModule } from '@pushdr/common/overlay';
import {
  ClipboardModule,
  CommonPipesModule,
  FocusOnElementModule,
  StorageService,
  TokenModule,
} from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { PatientappCommonComponentsModule } from '@pushdr/patientapp/common/components';
import { PatientappCommonConfigModule } from '@pushdr/patientapp/common/config';
import { ApiAccountPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { AccountProfileModule, PatientAnalyticsService } from '@pushdr/patientapp/common/utils';
import { SimpleModalModule } from 'ngx-simple-modal';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppConfigService } from './app.config.service';
import { BookingModule } from './booking/booking.module';
import { ProgressComponent } from './common/progress/progress.component';
import { OneAppVarient } from './flow-varients';
import { PatientModule } from './patient-profile/patient-profile.module';
import { RegisterModule } from './register/register.module';
import { FlowStateService } from './services/funnel-flow/flow-state.service';
import { FlowService } from './services/funnel-flow/flow.service';
import { LoginService } from './services/login/login.service';
import { NhsSignInOIDCService } from './services/nhs-signin/nhs-sign-in.service';
import { VerificationModule } from './verification/verification.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

// simulate a funnel being loaded from something async like A/B test setup
export function funnelFactory(envProxy: EnvironmentProxyService) {
  return new Promise((returnVarient, rej) => {
    returnVarient(OneAppVarient);
  });
}

export function loadConfig(appService: AppConfigService) {
  return () => appService.getAppConfig();
}

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [AppComponent, ProgressComponent],
  imports: [
    CommonPipesModule,
    BrowserAnimationsModule,
    PatientappCommonConfigModule,
    PatientappCommonComponentsModule,
    AccountProfileModule,
    FormsModule,
    BrowserModule,
    RouterModule,
    HttpClientModule,
    HttpClientJsonpModule,
    VerificationModule,
    PatientModule,
    BookingModule,
    RegisterModule, // i must me last because i have the ** routing fallback
    SimpleModalModule.forRoot(
      { container: document.body },
      {
        closeOnEscape: false,
        closeOnClickOutside: false,
        wrapperDefaultClasses: 'c-modal',
        bodyClass: 'js-modal-open',
        wrapperClass: 'c-modal--is-visible',
        animationDuration: 300,
        autoFocus: true,
      }
    ),
    CommonOverlayModule,
    SpinnerLoaderModule,
    TokenModule.forRoot({ tokenName: 'patient_token' }),
    FocusOnElementModule,
    ClipboardModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    {
      provide: EnvironmentProxyService,
      useValue: new EnvironmentProxyService().setup(environment),
    },
    {
      provide: AnalyticsService,
      useClass: PatientAnalyticsService,
    },
    {
      provide: LOG_CONFIG,
      useFactory: (accountApi: ApiAccountPatientService, storage: StorageService) => {
        return {
          applicationName: 'Patient Funnel',
          get: storage.getSessionStorage,
          set: storage.setSessionStorage,
          delete: storage.deleteSessionStorage,
          emitStored: logs => accountApi.addPatientLogRange(logs),
          emit: log => accountApi.addPatientLog(log),
        };
      },
      deps: [ApiAccountPatientService, StorageService],
    },
    {
      provide: 'FunnelResolver',
      useFactory: funnelFactory,
      deps: [EnvironmentProxyService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [AppConfigService],
      multi: true,
    },
    FlowStateService,
    FlowService,
    LoginService,
    NhsSignInOIDCService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
