import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnalyticsService } from '@pushdr/common/data-access/analytics';
import { ModalService } from '@pushdr/common/overlay';
import { Acceptance } from '@pushdr/common/types';
import { RecordAccessModalComponent } from '@pushdr/consent/components';

@Component({
  selector: 'pushdr-consent-non-governance',
  templateUrl: './consent-non-governance.component.html',
  styles: [],
})
export class ConsentNonGovernanceComponent {
  @Input() acceptance!: Acceptance;
  @Output() pdrAccepted = new EventEmitter<void>();

  get termsUrl(): string {
    return this.acceptance?.Content ?? '';
  }

  constructor(private modal: ModalService, private analytics: AnalyticsService) {}

  onAccept(): void {
    this.analytics.trackEvent({
      action: 'non_governance_acceptance_agreed',
      category: 'governance',
    });

    this.pdrAccepted.emit();
  }

  onDecline(): void {
    this.analytics.trackEvent({
      action: 'non_governance_acceptance_not_agreed',
      category: 'governance',
    });

    this.modal.showCustom(RecordAccessModalComponent);
  }
}
