import { NgModule } from '@angular/core';
import { ZendeskModule } from '@pushdr/zendesk';
import { MaintenanceComponent } from './maintenance.component';

@NgModule({
  exports: [MaintenanceComponent],
  declarations: [MaintenanceComponent],
  imports: [ZendeskModule],
})
export class MaintenanceModule {}
