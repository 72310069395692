<section class="c-form-part" trackView="dob">
  <h1 class="c-form-part__title" pdFocus="true">
    What’s your date of birth?
    <p class="u-sr-only">
      ,On this screen you will enter your date of birth using the 3 input boxes available. Then
      press the 'Next button' to continue.
    </p>
  </h1>
  <p tabindex="0">You must be 18 or over to use this service</p>
  <form novalidate #f="ngForm" (ngSubmit)="f.valid && submit()">
    <div
      class="c-form-part__margin-wrap"
      [class.c-form-part__entry--is-invalid]="(f.invalid || error) && f.submitted">
      <pushdr-input-dob
        #dobModel="ngModel"
        name="dobComposite"
        [(ngModel)]="dobComposite"></pushdr-input-dob>

      <p class="c-form-part__error-message" *ngIf="dobModel.hasError('invalidDay')" role="alert">
        The field for day 'DD' is invalid
      </p>
      <p class="c-form-part__error-message" *ngIf="dobModel.hasError('invalidMonth')" role="alert">
        The field for month 'MM' is invalid
      </p>
      <p class="c-form-part__error-message" *ngIf="dobModel.hasError('invalidYear')" role="alert">
        The field for year 'YYYY' is invalid
      </p>
      <p class="c-form-part__error-message" *ngIf="dobModel.hasError('invalidDate')" role="alert">
        Please enter a valid date in the format 'dd/mm/yyyy'
      </p>
      <p class="c-form-part__error-message" *ngIf="dobModel.hasError('tooYoung')" role="alert">
        Account holders cannot be under 18
      </p>
      <p class="c-form-part__error-message" *ngIf="error" role="alert">{{ error }}</p>
    </div>

    <button
      class="c-btn c-btn--primary c-form-part__btn"
      type="submit"
      value="Submit"
      trackClick="next"
      aria-label="Click next to continue">
      Next
    </button>
  </form>
</section>
