import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SpinnerLoaderModule, InputWithIconModule } from '@pushdr/common/components';
import { MobileNumInputTrimModule } from '../common/mobilefix/mobilenumfix.module';
import { TermsModule } from '../common/terms/terms.module';
import { MobileComponent } from './mobile/mobile.component';
import { ForgotPasswordComponent } from './password/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './password/reset-password/reset-password.component';
import { PasswordComponent } from './password/set-password/password.component';
import { PasswordInputModule } from '@pushdr/patientapp/common/components';
import { RegisterRoutingModule } from './register-routing.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { MaintenanceModule } from '../common/maintenance/maintenance.module';
import { AnalyticsDirectivesModule } from '@pushdr/common/data-access/analytics';
import { AccountProfileModule } from '@pushdr/patientapp/common/utils';
import { SimpleModalModule } from 'ngx-simple-modal';
import { A11yModule } from '@angular/cdk/a11y';
import { NhsSurgeryModule } from '../common/surgery/nhs-surgery.module';
import { NhsAuthorisationComponent } from './nhs-authorisation/nhs-authorisation.component';
import { NhsAuthorisationErrorModalComponent } from './nhs-authorisation/nhs-authorisation-error-modal/nhs-authorisation-error-modal.component';
import { WhatIsNHSLoginComponent } from './what-is-nhs-login/what-is-nhs-login.component';

@NgModule({
  imports: [
    CommonModule,
    AccountProfileModule,
    RegisterRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AnalyticsDirectivesModule,
    TermsModule,
    MaintenanceModule,
    MobileNumInputTrimModule,
    SpinnerLoaderModule,
    InputWithIconModule,
    NhsSurgeryModule,
    PasswordInputModule,
    SimpleModalModule,
    A11yModule,
  ],
  declarations: [
    PasswordComponent,
    SignUpComponent,
    MobileComponent,
    SignInComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NhsAuthorisationComponent,
    NhsAuthorisationErrorModalComponent,
    WhatIsNHSLoginComponent,
  ],
  providers: [],
})
export class RegisterModule {}
