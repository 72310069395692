<section class="c-form-part" trackView="forgot password">
  <h1 class="c-form-part__title">Reset password</h1>
  <p>
    Enter the email address and mobile number of your Push Doctor account and we'll text you a code
    to reset your password.
  </p>
  <form novalidate #f="ngForm" (ngSubmit)="f.valid && submit()" class="c-form-part__form">
    <div
      class="c-form-part__field-wrap"
      [class.c-form-part__entry--is-invalid]="f.invalid && f.submitted">
      <label class="c-form-part__field-label" for="ipt-mobile">Mobile Number</label>
      <input
        type="tel"
        [(ngModel)]="strMobile"
        pattern="^\+?(07[\d]{8,12}|447[\d]{7,11})\s*"
        #mobile="ngModel"
        name="mobile"
        class="c-form-part__field"
        id="ipt-mobile"
        aria-required="true"
        required
        aria-required="true"
        autocomplete="off" />
      <p class="c-form-part__error-message" *ngIf="mobile?.errors?.pattern" role="alert">
        Please enter a valid UK mobile number.
      </p>
      <p class="c-form-part__error-message" *ngIf="mobile?.errors?.required" role="alert">
        Mobile number is required.
      </p>
    </div>

    <div
      class="c-form-part__field-wrap"
      [class.c-form-part__entry--is-invalid]="f.invalid && f.submitted">
      <label class="c-form-part__field-label" for="ipt-strEmail">Email address</label>
      <input
        type="text"
        [(ngModel)]="strEmail"
        #email="ngModel"
        name="email"
        class="c-form-part__field"
        id="ipt-strEmail"
        aria-required="true"
        required
        aria-required="true" />
      <p
        class="c-form-part__error-message"
        id="err-msg-required-strEmail"
        *ngIf="email?.errors?.required">
        Please use a valid email format, e.g. name@domain.
      </p>
    </div>

    <button class="c-btn c-btn--primary c-form-part__btn" value="Log in" trackClick="request code">
      Send the code
    </button>
  </form>
  <p class="u-text-center">
    <a [routerLink]="['/register/login']" trackClick="sign up">Log into your account</a>
  </p>
</section>
