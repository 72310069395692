import { Component, OnInit } from '@angular/core';
import { GPPartnershipType, GPSearchResult } from '@pushdr/common/types';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '@pushdr/common/utils';
import { FlowStateService } from '../../../services/funnel-flow/flow-state.service';
import { GeneralPracticeService } from '@pushdr/patientapp/common/data-access/patient-api';
import { map, take } from 'rxjs/operators';
import { EnvironmentProxyService } from '@pushdr/environment';

@Component({
  selector: 'pushdr-pre-surgery-selector',
  templateUrl: './pre-surgery-selector.component.html',
  styleUrls: ['pre-surgery-selector.component.scss'],
})
export class PreSurgerySelectorComponent implements OnInit {
  searched = false;
  preSelected = false;

  constructor(
    private router: Router,
    private store: StorageService,
    private flowStateService: FlowStateService,
    private route: ActivatedRoute,
    private gpService: GeneralPracticeService,
    private envProxy: EnvironmentProxyService
  ) {}

  ngOnInit() {
    this.flowStateService.clearSelectedSurgeryStore();
    this.store.delete('patient-token');
    this.route.queryParams.pipe(take(1)).subscribe(p => {
      if (p.ods) {
        this.preSelectSurgeryFromQuery(p.ods);
      }
    });
  }

  preSelectSurgeryFromQuery(odsCode: string) {
    this.preSelected = true;
    this.gpService
      .getSurgery(odsCode)
      .pipe(
        take(1),
        map(surgery => {
          return {
            Id: surgery.Id,
            OdsCode: surgery.OdsCode,
            PartnershipType: GPPartnershipType.NHS_PARTNER,
            Name: surgery.Name,
          } as GPSearchResult;
        })
      )
      .subscribe(surgery => {
        this.selectSurgery(surgery, true);
      });
  }

  searchedToggle() {
    this.searched = true;
  }

  selectSurgery(selectedSurgery: GPSearchResult, isUrlSelectedSurgery = false) {
    this.searched = true;

    if (selectedSurgery.PartnershipType === GPPartnershipType.NHS_PARTNER)
      this.flowStateService.setSelectedSurgeryStore(
        { odsCode: selectedSurgery.OdsCode, surgeryName: selectedSurgery.Name },
        true
      );

    if (selectedSurgery.PartnershipType === GPPartnershipType.NOT_A_PARTNER) {
      window.location.href = this.envProxy.environment.patient.pdPrivateWebsiteBookingPage;
      return;
    }

    this.goToSurgeryDisplay(isUrlSelectedSurgery);
  }

  noSurgerySelected() {
    this.flowStateService.setSelectedSurgeryStore();
    this.goToSurgeryDisplay();
  }

  private goToSurgeryDisplay(isUrlSelectedSurgery = false) {
    this.router.navigate(['surgery-preselect', 'selected'], { replaceUrl: isUrlSelectedSurgery });
  }
}
