import { FactoryProvider } from '@angular/core';
import { Router } from '@angular/router';

import { EnvironmentProxyService } from '@pushdr/environment';
import { ConsentHandler, CONSENT_HANDLER } from '@pushdr/consent/shell';

// Consent handler to redirect users on terms accept/reject
export const consentHandlerProvider: FactoryProvider = {
  provide: CONSENT_HANDLER,
  useFactory: consentHanderFactory,
  deps: [Router, EnvironmentProxyService],
};

function consentHanderFactory(router: Router, env: EnvironmentProxyService): ConsentHandler {
  const accountUrl = env.environment.patient.account.url;
  return {
    accepted: () => router.navigate(['/booking/emergency']),
    rejected: () => (window.location.href = accountUrl),
  };
}
