<div class="o-row o-row--no-margin-top o-row--no-margin-bottom">
  <div class="col-xs-12">
    <div class="c-form-part__progress-bar">
      <div
        class="c-form-part__progress-bar-inner"
        style="transition: width 500ms ease-out"
        [ngStyle]="{ width: (percentageComplete$ | async) + '%' }"></div>
    </div>
  </div>
</div>
