import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, tap } from 'rxjs';

import { ExtendedWindow, WINDOW } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { BookingService } from '@pushdr/patientapp/booking/common';

@Injectable({
  providedIn: 'root',
})
export class IsBookingEnabledGuard implements CanActivate {
  constructor(
    private booking: BookingService,
    private envProxy: EnvironmentProxyService,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {}
  canActivate(): Observable<boolean> {
    return this.booking.isBookingEnabled().pipe(
      tap(isBookingEnabled => {
        if (!isBookingEnabled) {
          this.window.location.href = this.envProxy.environment.patient.account.url;
        }
      })
    );
  }
}
