import { Component } from '@angular/core';
import { CustomerApiService } from '@pushdr/patientapp/common/data-access/patient-api';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pushdr-home-nhs',
  templateUrl: './home-nhs.component.html',
})
export class HomeNhsComponent {
  partnerName$ = this.customer.getPartner().pipe(map(partner => partner?.Name));

  constructor(private customer: CustomerApiService) {}
}
