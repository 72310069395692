import { Component, OnInit, Inject } from '@angular/core';
import { WINDOW, ExtendedWindow } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import {
  AccountApiService,
  ApiNHSPatientService,
} from '@pushdr/patientapp/common/data-access/patient-api';
import { ModalService } from '@pushdr/common/overlay';
import { delay } from 'rxjs/operators';
import { FlowStateService } from '../../../services/funnel-flow/flow-state.service';
@Component({
  selector: 'pushdr-surgery-notify',
  styleUrls: ['./surgery-notify.component.scss'],
  templateUrl: './surgery-notify.component.html',
})
export class SurgeryNotifyComponent implements OnInit {
  notified = false;
  form: UntypedFormGroup;
  proceedToAccount = false;
  emailAddressRegex =
    /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)/;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private envProxy: EnvironmentProxyService,
    private api: AccountApiService,
    private modal: ModalService,
    private flowStateService: FlowStateService,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {}

  ngOnInit() {
    const existingUserWithAccount = location.href.indexOf('verification') > -1;
    this.notified = existingUserWithAccount;
    this.proceedToAccount = existingUserWithAccount;

    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.emailAddressRegex)]],
    });
  }

  onSubmit() {
    this.showLoader();
    this.api
      .requestNotificationForGPPartner(
        this.flowStateService.getSelectedSurgeryStore().odsCode as any,
        this.form.value.email
      )
      .pipe(delay(1000))
      .subscribe(
        res => {
          this.modal.close();
          this.notified = true;
          this.flowStateService.clearSelectedSurgeryStore();
        },
        error => {
          this.modal.error(error);
        }
      );
  }

  goBackToHome() {
    this.window.location.href = this.proceedToAccount
      ? this.envProxy.environment.patient.account.url
      : 'https://www.pushdoctor.co.uk/nhs';
  }

  private showLoader() {
    this.modal.showLoader({
      header: 'Adding your email to be notified',
      bottomText: 'Please be patient while we add your email to the list to be notified',
    });
  }
}
