import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonComponentsModule } from '@pushdr/common/components';
import { AnalyticsDirectivesModule } from '@pushdr/common/data-access/analytics';
import { HomeAddressModule, SharedEditFormModule } from '@pushdr/patientapp/common/components';
import { AccountProfileModule } from '@pushdr/patientapp/common/utils';
import { AddressSearchComponent } from './address/address-search/address-search.component';
import { DobComponent } from './dob/dob.component';
import { GenderComponent } from './gender/gender.component';
import { IsSigningUpGuard } from './is-signing-up.guard';
import { NameComponent } from './name/name.component';
import { PatientRoutingModule } from './patient-profile-routing.module';
import { LottieModule } from 'ngx-lottie';
import { IsAccountSetupCompleteGuard } from './is-account-setup-complete-guard';

@NgModule({
  imports: [
    CommonModule,
    AccountProfileModule,
    PatientRoutingModule,
    SharedEditFormModule,
    FormsModule,
    NgSelectModule,
    LottieModule,
    AnalyticsDirectivesModule,
    CommonComponentsModule,
    HomeAddressModule,
  ],
  declarations: [NameComponent, GenderComponent, AddressSearchComponent, DobComponent],
  providers: [IsSigningUpGuard, IsAccountSetupCompleteGuard],
})
export class PatientModule {}
