import { NgModule } from '@angular/core';
import { PreSurgerySelectorComponent } from './pre-surgery-selector/pre-surgery-selector.component';
import { SharedEditFormModule } from '@pushdr/patientapp/common/components';
import { SpinnerLoaderModule } from '@pushdr/common/components';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, SharedEditFormModule, SpinnerLoaderModule],
  exports: [PreSurgerySelectorComponent],
  declarations: [PreSurgerySelectorComponent],
  providers: [],
})
export class NhsSurgeryModule {}
