import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiNHSPatientService } from '@pushdr/patientapp/common/data-access/patient-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class IsAccountSetupCompleteGuard implements CanActivate {
  constructor(private api: ApiNHSPatientService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.api.customer.getCustomer().pipe(
      map(customer => {
        if (!!customer.Gender && customer.Gender === 3) {
          return true;
        } else {
          this.router.navigate(['/register', 'signup'], { replaceUrl: true });
          return false;
        }
      })
    );
  }
}
