import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TermsComponent } from '../common/terms/terms.component';
import { AddressSearchComponent } from './address/address-search/address-search.component';
import { DobComponent } from './dob/dob.component';
import { GenderComponent } from './gender/gender.component';
import { NameComponent } from './name/name.component';
import { IsSigningUpGuard } from './is-signing-up.guard';
import { IsAccountSetupCompleteGuard } from './is-account-setup-complete-guard';

const routes: Routes = [
  {
    path: 'patient',
    redirectTo: '/patient/terms',
    pathMatch: 'full',
  },
  {
    path: 'patient',
    canActivate: [IsSigningUpGuard],
    children: [
      {
        path: 'terms',
        component: TermsComponent,
        canActivate: [IsAccountSetupCompleteGuard],
      },
      {
        path: 'name',
        component: NameComponent,
        canActivate: [IsAccountSetupCompleteGuard],
      },
      {
        path: 'dob',
        component: DobComponent,
        canActivate: [IsAccountSetupCompleteGuard],
      },
      {
        path: 'gender',
        component: GenderComponent,
        canActivate: [IsAccountSetupCompleteGuard],
      },
      {
        path: 'address',
        component: AddressSearchComponent,
        canActivate: [IsAccountSetupCompleteGuard],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: true, relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class PatientRoutingModule {}
