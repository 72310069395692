<pushdr-alert-bar *ngIf="isIE10" type="warning" dismissable="true" top="true">
  <strong>Improve your experience!</strong> You are using an outdated browser; please upgrade to a
  newer browser for a better experience on PushDoctor. Here are the instructions on how to
  <strong><a href="http://browsehappy.com/" target="_blank">upgrade your web browser</a></strong
  >.
</pushdr-alert-bar>

<pushdr-header [type]="getHeaderType()"></pushdr-header>
<div class="o-wrapper o-container o-container--fluid" style="min-height: 480px">
  <div class="o-row o-row--margin-bottom-small">
    <div class="col-xs-12">
      <pushdr-progress *ngIf="!isBookingUrl"></pushdr-progress>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<pushdr-footer [type]="getFooterType()"></pushdr-footer>
